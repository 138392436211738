
export default {
  name: 'BaseInput',
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  props: {
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    hideLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    wrapperClass: {
      type: [Object, Array, String],
      required: false,
      default: ''
    },
    inputWrapperClass: {
      type: [Object, Array, String],
      required: false,
      default: ''
    },
    inputClass: {
      type: [Object, Array, String],
      required: false,
      default: ''
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    validator: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  emits: ['update:modelValue'],
  computed: {
    hasValue () { return this.modelValue !== '' && this.modelValue !== undefined },
    hasValidator () { return !!Object.keys(this.validator).length },
    hasLeftSlot () { return !!this.$slots.left },
    hasRightSlot () { return !!this.$slots.right },
    errorMessage () { return this.hasValidator && this.validator.$errors?.length ? this.validator.$errors[0].$message : '' },
    labelText () { return this.label + (this.required ? ' *' : '') },
    placeholderText () { return this.hideLabel ? this.placeholder : '' },
    id () { return this.$attrs.id || this.name }
  }
}
