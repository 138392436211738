import type { CartItem } from '~/types/item'
import type { DeliveryMethod, DeliveryMethodGroup } from '~/types/checkout'

export function useShipping () {
  const getStaticDeliveryMethodsOfLargestCartItem = (cartItems: CartItem[]): DeliveryMethod[] => {
    let shippings: DeliveryMethod[] = []
    // loop all cart items to get shipping prices
    cartItems.forEach((item) => {
      if (shippings.length === 0) {
        shippings = Object.assign([], item.shipping)
      } else if (item.shipping.length > 0 && item.shipping[0].price > shippings[0].price) {
        // check both items lowest shipping price, replace if its bigger
        shippings = Object.assign([], item.shipping)
      }
    })
    // Sort ascending by shipping price, except if a zero cost shipping, the leave as last items
    return shippings.sort((a, b) => (a.price > b.price || a.price === 0) ? 1 : -1)
  }

  const getCheapestPaidDeliveryCost = (deliveryMethods: DeliveryMethod[]): number => {
    const deliveryCosts = deliveryMethods.map(dm => dm.price).filter(price => price > 0)
    return Math.min(...deliveryCosts)
  }

  const groupDeliveryMethods = (deliveryMethods: DeliveryMethod[]): DeliveryMethodGroup[] => {
    const groupsMap = deliveryMethods.reduce((acc: { [key: string]: DeliveryMethodGroup }, dm) => {
      if (!acc[dm.groupId]) {
        acc[dm.groupId] = {
          id: dm.groupId,
          label: dm.groupName,
          component: dm.groupId === 'pickup_parcel' ? 'delivery-pickup-list' : 'delivery-input-list',
          deliveryMethods: [],
          lowestPrice: dm.price,
          highestPrice: dm.price
        }
      }
      acc[dm.groupId].deliveryMethods.push(dm)
      acc[dm.groupId].lowestPrice = Math.min(acc[dm.groupId].lowestPrice, dm.price)
      acc[dm.groupId].highestPrice = Math.max(acc[dm.groupId].highestPrice, dm.price)
      return acc
    }, {})

    return Object.values(groupsMap)
  }

  return {
    getStaticDeliveryMethodsOfLargestCartItem,
    getCheapestPaidDeliveryCost,
    groupDeliveryMethods
  }
}
