import type { CustomSize } from '~/types/customization'
import type { Parcel, ProductAlgolia, ProductCategory, ProductImage, ProductImageVariants } from '~/types/product'

export const formatItemVersion = (itemVersionStr: string): number => {
  // @ts-ignore
  return parseInt(itemVersionStr || '') || 1
}

/*
 * IMAGES
 */

export const getUrlFromImage = (image: ProductImage, variant?: keyof ProductImageVariants): string => (variant && image.variants) ? (image.variants[variant] || image.url || '') : image.url || ''

export const getMainImage = (product: ProductAlgolia, variant?: keyof ProductImageVariants) => {
  const image = product?.defaultImage || product?.defaultVariationImage
  return image ? getUrlFromImage(image, variant) : ''
}
export const getVariationImage = (product: ProductAlgolia, variant?: keyof ProductImageVariants) => {
  const image = product?.defaultVariationImage || product?.images[0]
  return image ? getUrlFromImage(image, variant) : ''
}
export const getVariationImageMasked = (product: ProductAlgolia, variant?: keyof ProductImageVariants) => {
  const image = product?.images.find((image: ProductImage) => !image.shotAngle?.toLowerCase().includes('miljöö')) || product?.defaultVariationImage
  return image ? getUrlFromImage(image, variant) : ''
}
export const getUpholsteryImages = (product: ProductAlgolia) => product?.images.filter((image: ProductImage) => image.shotAngle?.toLowerCase().includes('verhoilunäyte'))

/**
 * Parcels
 */

const getFallbackParcelAmountByProductCategory = (productCategories: ProductCategory[]): number => {
  // Default parcel amount if no main category is found and/or mapped
  const defaultParcelAmount = 6
  // Main categories, the order of the categories is important and determines the main category if the product contains multiple categories
  // Currently only level 1 categories are used, but this could be extended to lower level categories
  // E.g. 'Huonekalut' could be broken down to 'Sohvat', 'Sängyt', 'Tuolit', 'Pöydät', 'Ruokailuryhmät' etc.
  const parcelAmountsByCategories: { key: string, parcelAmount: number }[] = require('../config/delivery-method-fallback-parcel-amounts.json')

  const mainCategory = productCategories
    .filter(cat => parcelAmountsByCategories.some(mainCat => mainCat.key === cat.value)) // Filter categories based on 'key' in mainCategories
    .sort((a, b) => parcelAmountsByCategories.findIndex(mainCat => mainCat.key === a.value) - parcelAmountsByCategories.findIndex(mainCat => mainCat.key === b.value)) // Sort by 'key' position in mainCategories
    .shift()?.value // Return the first item

  // Retrieve the 'value' of the matched main category, or use a default value
  const parcelAmount = parcelAmountsByCategories.find(mainCat => mainCat.key === mainCategory)?.parcelAmount

  return parcelAmount || defaultParcelAmount
}

export const getFallbackParcels = (product: ProductAlgolia): Parcel[] => {
  return [{
    depth: 100,
    width: 100,
    height: 100,
    weight: 30,
    count: getFallbackParcelAmountByProductCategory(product.categories),
    stackable: true,
    type: 'PARCEL'
  }]
}

export const getCustomSizeParcels = (product: ProductAlgolia, customSize: CustomSize): Parcel[] => {
  const parcels: Parcel[] = []
  if (customSize.value) {
    const squareWeight = product.measurements.itemSquareWeight?.value as number || 1.8
    const customWeight = ((customSize.value.width * customSize.value.length) / 10000) * squareWeight
    parcels.push(
      {
        weight: customWeight,
        width: customSize.value.width,
        depth: 20,
        height: 20,
        count: 1,
        stackable: true,
        type: 'PARCEL'
      }
    )
  }

  return parcels.length ? parcels : getFallbackParcels(product)
}

/**
 * Calculate parcels based on itemsInPackage and multiplier
 *
 * @param parcels Original product parcels
 * @param itemsInPackage Sales units in a package
 * @param multiplier Cart row quantity (default 1)
 * @returns itemsInPackage modified parcels
 */
export const calculateParcels = (parcels: Parcel[], itemsInPackage: number | undefined, multiplier: number = 1): Parcel[] => {
  const itemsInPackageInt = itemsInPackage || 1
  const result: Parcel[] = parcels.map((parcel: Parcel) => {
    const count: number = Math.ceil(((1 / itemsInPackageInt) * multiplier))
    // Round up to 2 decimal places
    const weight: number = Math.ceil(((parcel.weight / itemsInPackageInt) * multiplier) * 100) / 100
    return { ...parcel, weight, count }
  })

  return result
}
