import { GetterTree, ActionTree, MutationTree } from 'vuex'
import type { AxiosResponse } from 'axios'
import { RootState } from '~/store'
import { useShipping } from '~/composable/useShipping'
import { CartGetters } from '~/store/cart'
import type { PaymentCart } from '~/types/paymentGateway'
import type { DeliveryMethod, DeliveryMethodGroup, DeliveryMethodServicePointResultData, DeliveryMethodQueryResult, DeliveryMethodServicePointQueryResult, PaymentMethodGroup, PaymentMethod, PaymentMethodGroupWithOptions, CheckoutStepIndex, PartPaymentPlan, PartPaymentPlanCampaign, CustomerInfo, UserInfo, DeliveryMethodServicePoint, ServiceProvider, DeliveryMethodDiscountRule } from '~/types/checkout'
import type { CartAddress } from '~/types/cart'
import type { UserAddress } from '~/types/user'
import type { Parcel } from '~/types/product'

const getClearAddress = (): CartAddress => ({
  firstname: '',
  lastname: '',
  phone: '',
  street1: '',
  postcode: '',
  city: '',
  countryCode: 'FI' as 'FI'
})
const getClearState = () => ({
  dynamicDeliveryMethods: [] as DeliveryMethod[],
  selectedDeliveryMethod: null as DeliveryMethod | null,
  lastDeliveryMethodPostcode: '00200',
  lastSuccessfullDeliveryMethodPostcode: '00200',
  deliveryMethodFetchError: null as DeliveryMethodQueryResult | null,
  deliveryMethodServicePoints: [] as DeliveryMethodServicePointResultData[],
  deliveryMethodServicePointFetchErrors: [] as DeliveryMethodServicePointQueryResult[],
  isFetchingDeliveryMethodServicePoints: false,
  selectedDeliveryMethodServicePoint: null as DeliveryMethodServicePoint | null,
  deliveryMethodDiscountRules: null as DeliveryMethodDiscountRule[] | null,
  isFetchingDeliveryMethodDiscountRules: false,
  allPaymentMethodGroups: require('~/config/payment-method-groups.json') as PaymentMethodGroup[],
  availablePaymentMethods: [] as PaymentMethod[],
  selectedPaymentMethod: null as PaymentMethod | null,
  activeCheckoutStepIndex: 0 as CheckoutStepIndex,
  isFetchingPaymentMethods: false,
  isFetchingDeliveryMethods: false,
  hasPaymentMethodFetchFailed: false,
  paymentMethodsFetchedWithSum: null as number | null,
  hasSeparateShippingAddress: false,
  saveAsDefault: false,
  partPaymentPlan: null as PartPaymentPlan | null,
  user: {
    firstname: '',
    lastname: '',
    phone: '',
    email: ''
  } as UserInfo,
  addresses: {
    billing: getClearAddress(),
    shipping: getClearAddress()
  },
  comment: '',
  partialDelivery: false,
  subscribeNewsletter: false
})

export const state = () => getClearState()

export type CheckoutState = ReturnType<typeof state>

export enum CheckoutActions {
  fetchDeliveryMethodsByParcels = 'checkout/fetchDeliveryMethodsByParcels',
  fetchDeliveryMethodsForCheckout = 'checkout/fetchDeliveryMethodsForCheckout',
  fetchDeliveryMethodServicePointsByPostcode = 'checkout/fetchDeliveryMethodServicePointsByPostcode',
  selectPaymentMethod = 'checkout/selectPaymentMethod',
  clearSelectedDeliveryMethod = 'checkout/clearSelectedDeliveryMethod',
  clearSelectedPaymentMethod = 'checkout/clearSelectedPaymentMethod',
  clearComment = 'checkout/clearComment',
  fetchPaymentMethods = 'checkout/fetchPaymentMethods',
  activateStep = 'checkout/activateStep',
  setAddressData = 'checkout/setAddressData',
  hasSeparateShippingAddress = 'checkout/hasSeparateShippingAddress',
  selectDeliveryMethod = 'checkout/selectDeliveryMethod',
  selectServicePoint = 'checkout/selectServicePoint',
  validateSelectedPaymentMethod = 'checkout/validateSelectedPaymentMethod',
  payOrder = 'checkout/payOrder',
  clearCheckout = 'checkout/clearCheckout',
  selectOnlyPaymentMethod = 'checkout/selectOnlyPaymentMethod',
  fetchPartPaymentPlanForAmount = 'checkout/fetchPartPaymentPlanForAmount',
  prefillUserAddresses = 'checkout/prefillUserAddresses',
  saveCustomerInfo = 'checkout/saveCustomerInfo',
  setPartialDelivery = 'checkout/setPartialDelivery',
  subscribeNewsletter = 'checkout/subscribeNewsletter',
  setDeliveryMethodFetchError = 'checkout/setDeliveryMethodFetchError',
  fetchDeliveryMethodDiscountRules = 'checkout/fetchDeliveryMethodDiscountRules',
  applyDeliveryMethodDiscountRule = 'checkout/applyDeliveryMethodDiscountRule',
  setIsFetchingDeliveryMethodDiscountRules = 'checkout/setIsFetchingDeliveryMethodDiscountRules',
}

export enum CheckoutMutations {
  setSelectedDeliveryMethod = 'checkout/SET_SELECTED_DELIVERY_METHOD',
  setSelectedDeliveryMethodServicePoint = 'checkout/SET_SELECTED_DELIVERY_SERVICE_POINT',
  setSelectedPaymentMethod = 'checkout/SET_SELECTED_PAYMENT_METHOD',
  setPaymentMethods = 'checkout/SET_PAYMENT_METHODS',
  setActiveCheckoutStepIndex = 'checkout/SET_ACTIVE_CHECKOUT_STEP_INDEX',
  setIsFetchingPaymentMethods = 'checkout/IS_FETCHING_PAYMENT_METHODS',
  setHasPaymentMethodFetchFailed = 'checkout/HAS_PAYMENT_METHOD_FETCH_FAILED',
  setPaymentMethodsFetchedWithSum = 'checkout/PAYMENT_METHODS_FETCHED_WITH_SUM',
  setComment = 'checkout/SET_COMMENT',
  setBillingAddress = 'checkout/SET_BILLING_ADDRESS',
  setShippingAddress = 'checkout/SET_SHIPPING_ADDRESS',
  setCustomerInfo = 'checkout/SET_CUSTOMER_INFO'
}

export enum CheckoutGetters {
  getDynamicDeliveryMethods = 'checkout/getDynamicDeliveryMethods',
  getSelectedDeliveryMethod = 'checkout/getSelectedDeliveryMethod',
  getGroupedDeliveryMethodsForCart = 'checkout/getGroupedDeliveryMethodsForCart',
  getSelectedPaymentMethod = 'checkout/getSelectedPaymentMethod',
  getGroupedPaymentMethods = 'checkout/getGroupedPaymentMethods',
  getGroupOfSelectedPaymentMethod = 'checkout/getGroupOfSelectedPaymentMethod',
  getDeliveryMethodsForCart = 'checkout/getDeliveryMethodsForCart',
  getDeliveryMethodDiscountRules = 'checkout/getDeliveryMethodDiscountRules',
  getAvailablePaymentMethods = 'checkout/getAvailablePaymentMethods',
  getActiveCheckoutStepIndex = 'checkout/getActiveCheckoutStepIndex',
  hasFetchingPaymentMethodsFailed = 'checkout/hasFetchingPaymentMethodsFailed',
  isFetchingPaymentMethods = 'checkout/isFetchingPaymentMethods',
  getPaymentMethodsFetchedWithSum = 'checkout/getPaymentMethodsFetchedWithSum',
  getAddresses = 'checkout/getAddresses',
  getBillingAddress = 'checkout/getBillingAddress',
  getShippingAddress = 'checkout/getShippingAddress',
  getUser = 'checkout/getUser',
  hasSeparateShippingAddress = 'checkout/hasSeparateShippingAddress',
  saveAsDefault = 'checkout/saveAsDefault',
  getComment = 'checkout/getComment',
  getGrandTotal = 'checkout/getGrandTotal',
  getPartPaymentPlanForCart = 'checkout/getPartPaymentPlanForCart',
  getGiftCardAmountToUse = 'checkout/getGiftCardAmountToUse',
  getGrandTotalBeforeGiftCard = 'checkout/getGrandTotalBeforeGiftCard',
  getPartPaymentPlansSortedByContractLength = 'checkout/getPartPaymentPlansSortedByContractLength',
  getPartPaymentPlanSortedByMonthlyAnnuity = 'checkout/getPartPaymentPlanSortedByMonthlyAnnuity',
  getCampaignPartPaymentPlans = 'checkout/getCampaignPartPaymentPlans',
  getLongestCampaignPartPaymentPlan = 'checkout/getLongestCampaignPartPaymentPlan',
  getCheapestMonthlyAnnuityPaymentPlan = 'checkout/getCheapestMonthlyAnnuityPaymentPlan',
  getDeliveryFee = 'checkout/getDeliveryFee',
  getSubscribeNewsletter = 'checkout/getSubscribeNewsletter',
  getPartialDelivery = 'checkout/getPartialDelivery',
  getIsFetchingDeliveryMethods = 'checkout/getIsFetchingDeliveryMethods',
  getLastDeliveryMethodPostcode = 'checkout/getLastDeliveryMethodPostcode',
  getLastSuccessfullDeliveryMethodPostcode = 'checkout/getLastSuccessfullDeliveryMethodPostcode',
  getDeliveryMethodFetchError = 'checkout/getDeliveryMethodFetchError',
  getDeliveryMethodFetchFriendlyError = 'checkout/getDeliveryMethodFetchFriendlyError',
  getSelectedDeliveryMethodServicePoint = 'checkout/getSelectedDeliveryMethodServicePoint',
  getDeliveryMethodServicePoints = 'checkout/getDeliveryMethodServicePoints',
  getIsFetchingDeliveryMethodServicePoints = 'checkout/getIsFetchingDeliveryMethodServicePoints',
  getDeliveryMethodServicePointsByServiceProvider = 'checkout/getDeliveryMethodServicePointsByServiceProvider',
  getDeliveryMethodServicePointFetchErrorByServiceProvider = 'checkout/getDeliveryMethodServicePointFetchErrorByServiceProvider',
  getDeliveryMethodServicePointFetchErrors = 'checkout/getDeliveryMethodServicePointFetchErrors',
  getIsFetchingDeliveryMethodDiscountRules = 'checkout/getIsFetchingDeliveryMethodDiscountRules',
  deliveryMethodDiscountAvailable = 'checkout/deliveryMethodDiscountAvailable'
}

export const actions: ActionTree<CheckoutState, RootState> = {
  subscribeNewsletter ({ commit }, payload: boolean) {
    commit('SET_SUBSCRIBE_NEWSLETTER', payload)
  },
  async fetchDeliveryMethodsByParcels ({ state, commit, rootGetters }, payload: { parcels: Parcel[], targetPostcode?: string }) {
    // Skip fetching if cart is reloading items (e.g. after invalidation)
    // Fetching delivery items without having reloaded the cart completely, will result in incorrect delivery methods
    if (!rootGetters[CartGetters.reloading]) {
      commit('IS_FETCHING_DELIVERY_METHODS', true)
      commit('SET_DELIVERY_METHOD_FETCH_ERROR', null)
      commit('RESET_DYNAMIC_DELIVERY_METHODS')
      commit('SET_SELECTED_DELIVERY_METHOD', null)
      commit('SET_SELECTED_DELIVERY_SERVICE_POINT', null)

      if (!payload.targetPostcode) {
        payload.targetPostcode = state.lastDeliveryMethodPostcode
      }
      // @ts-ignore
      await this.$axios.post(`${this.$config.FUNCTIONS_API_URL}/veke3000-shipping-getAvailableShippingMethodsByCartItems`, payload)
        .then((response: AxiosResponse<DeliveryMethodQueryResult>) => {
          if (response.data.status === 'error') {
            commit('SET_DELIVERY_METHOD_FETCH_ERROR', response.data as DeliveryMethodQueryResult)
          } else {
            commit('SET_DYNAMIC_DELIVERY_METHODS', response.data.prices)
            commit('SET_LAST_SUCCESSFULL_DELIVERY_METHOD_POSTCODE', payload.targetPostcode)
          }
        })
        .catch((error) => {
          commit('SET_DELIVERY_METHOD_FETCH_ERROR', {
            query_id: '',
            status: 'error',
            statusCode: 500,
            error: 'Toimitustapojen haku epäonnistui',
            error_code: 'CUSTOM_UNKNOWN_ERROR'
          })
          console.error(error)
        })
        .finally(() => {
          commit('SET_LAST_DELIVERY_METHOD_POSTCODE', payload.targetPostcode)
          commit('IS_FETCHING_DELIVERY_METHODS', false)
        })
    }
  },
  async fetchDeliveryMethodServicePointsByPostcode ({ commit }, payload: { postcode: string, parcels: Parcel[] }) {
    commit('IS_FETCHING_DELIVERY_METHOD_SERVICE_POINTS', true)
    commit('SET_DELIVERY_METHOD_SERVICE_POINT_FETCH_ERRORS', [])
    // @ts-ignore
    await this.$axios.post(`${this.$config.FUNCTIONS_API_URL}/veke3000-shipping-getShippingMethodServicePointsByPostcode`, payload)
      .then((response: AxiosResponse<DeliveryMethodServicePointQueryResult[]>) => {
        commit('SET_DELIVERY_METHOD_SERVICE_POINT_FETCH_ERRORS', response.data.filter(res => res.statusCode !== 200) as DeliveryMethodServicePointQueryResult[])
        commit('SET_AVAILABLE_DELIVERY_METHOD_SERVICE_POINTS', response.data.filter(res => res.statusCode === 200).map(res => res.result))
        commit('SET_SELECTED_DELIVERY_SERVICE_POINT', null)
      })
      .catch((error) => {
        commit('SET_DELIVERY_METHOD_SERVICE_POINT_FETCH_ERRORS', [{
          query_id: '',
          status: 'error',
          statusCode: 500,
          error: 'Tapahtui tuntematon virhe haettaessa toimituspisteitä',
          error_code: 'CUSTOM_UNKNOWN_ERROR'
        }])
        console.error(error)
      })
      .finally(() => {
        commit('IS_FETCHING_DELIVERY_METHOD_SERVICE_POINTS', false)
      })
  },
  async fetchDeliveryMethodDiscountRules ({ commit }) {
    commit('IS_FETCHING_DELIVERY_METHOD_DISCOUNTS', true)
    // @ts-ignore
    await this.$axios.get(`${this.$config.FUNCTIONS_API_URL}/veke3000-campaign-getDeliveryMethodDiscountV2`)
      .then((response: AxiosResponse<DeliveryMethodDiscountRule[]>) => {
        const discountRules = response.data
        // sort discount rules descending by minPrice
        if (discountRules) {
          discountRules.sort((a, b) => b.minPrice - a.minPrice)
        }
        commit('SET_DELIVERY_METHOD_DISCOUNTS', discountRules)
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        commit('IS_FETCHING_DELIVERY_METHOD_DISCOUNTS', false)
      })
  },
  async fetchDeliveryMethodsForCheckout ({ state, dispatch, rootGetters }, postcode) {
    const cartParcels = rootGetters[CartGetters.getCartParcels]
    const deliveryMethods = dispatch('fetchDeliveryMethodsByParcels', { parcels: cartParcels, targetPostcode: postcode })
    const deliveryDiscounts = dispatch('fetchDeliveryMethodDiscountRules')
    await Promise.all([deliveryMethods, deliveryDiscounts])
    if (state.deliveryMethodFetchError) {
      return
    }
    dispatch('applyDeliveryMethodDiscountRule')
    if (state.dynamicDeliveryMethods?.find((dm: DeliveryMethod) => dm.groupId === 'pickup_parcel')) {
      dispatch('fetchDeliveryMethodServicePointsByPostcode', { postcode, parcels: cartParcels })
    }
  },
  applyDeliveryMethodDiscountRule ({ state, commit, rootGetters }) {
    const cartItemAmount = rootGetters[CartGetters.totalItemAmount]
    if (state.deliveryMethodDiscountRules) {
      const deliveryMethods = state.dynamicDeliveryMethods.map((dm: DeliveryMethod) => {
        // Find first discount rule which applies to this delivery method
        const deliveryMethodDiscountRule = state.deliveryMethodDiscountRules?.find((rule: DeliveryMethodDiscountRule) =>
          rule.shippingServiceCodes.includes(dm.id) && rule.active && cartItemAmount >= rule.minCartProductAmount && dm.price >= rule.minPrice)

        if (deliveryMethodDiscountRule) {
          const discount = deliveryMethodDiscountRule.discountAmount
          if (deliveryMethodDiscountRule.discountType === 'percent') {
            dm.price = dm.originalPrice * (1 - (discount / 100))
          } else {
            // Fixed discount
            dm.originalPrice = dm.price - discount
          }
        }

        return dm
      })

      commit('SET_DYNAMIC_DELIVERY_METHODS', deliveryMethods)
    }
  },
  setDeliveryMethodFetchError ({ commit }, payload: DeliveryMethodQueryResult|null) {
    commit('SET_DELIVERY_METHOD_FETCH_ERROR', payload)
  },
  clearSelectedDeliveryMethod ({ commit }) {
    commit('SET_SELECTED_DELIVERY_METHOD', null)
  },
  clearSelectedPaymentMethod ({ commit }) {
    commit('SET_SELECTED_PAYMENT_METHOD', null)
  },
  setIsFetchingDeliveryMethodDiscountRules ({ commit }, payload: boolean) {
    commit('IS_FETCHING_DELIVERY_METHOD_DISCOUNTS', payload)
  },
  clearComment ({ commit }) {
    commit('SET_COMMENT', '')
  },
  selectPaymentMethod ({ commit }, payload: PaymentMethod) {
    commit('SET_SELECTED_PAYMENT_METHOD', payload)
  },
  selectDeliveryMethod ({ commit }, payload: DeliveryMethod) {
    commit('SET_SELECTED_DELIVERY_METHOD', payload)
  },
  selectServicePoint ({ commit }, payload: DeliveryMethodServicePoint | null) {
    commit('SET_SELECTED_DELIVERY_SERVICE_POINT', payload)
  },
  activateStep ({ commit }, payload: CheckoutStepIndex) {
    commit('SET_ACTIVE_CHECKOUT_STEP_INDEX', payload)
  },
  async fetchPaymentMethods ({ commit, getters }) {
    commit('HAS_PAYMENT_METHOD_FETCH_FAILED', false)
    const grandTotal = getters.getGrandTotal
    let availablePaymentMethods: PaymentMethod[] = []
    if (getters.getPaymentMethodsFetchedWithSum === grandTotal) {
      return
    } else if (grandTotal === 0) {
      availablePaymentMethods.push({
        displayName: 'Lahjakortti',
        code: 'zeroPayment',
        imageUrl: ''
      })
    } else {
      commit('IS_FETCHING_PAYMENT_METHODS', true)
      // @ts-ignore
      const sveaPaymentMethods = await this.$axios.get(this.$config.FUNCTIONS_API_URL + '/veke3000-paymentGateway-providers-svea-getPaymentMethodsV2?amount=' + grandTotal.toFixed(2))
        .catch(() => {
          commit('HAS_PAYMENT_METHOD_FETCH_FAILED', true)
          return { data: [] }
        })
      if (sveaPaymentMethods.data.length) {
        commit('PAYMENT_METHODS_FETCHED_WITH_SUM', grandTotal)
      }
      availablePaymentMethods = [
        ...sveaPaymentMethods.data,
        {
          displayName: 'Klarna',
          code: 'KLARNA',
          imageUrl: '/assets/images/checkout/payment-icons/klarna.png'
        }
      ]
    }
    commit('SET_PAYMENT_METHODS', availablePaymentMethods)
    commit('IS_FETCHING_PAYMENT_METHODS', false)
  },
  async fetchPartPaymentPlanForAmount ({ commit }, payload: number) {
    commit('SET_PART_PAYMENT_PLAN', null)
    // @ts-ignore TODO add error handling
    const partPaymentPlan = await this.$axios.get(this.$config.FUNCTIONS_API_URL + '/veke3000-paymentGateway-providers-svea-fetchPartPaymentPlanV2?amount=' + payload)
    commit('SET_PART_PAYMENT_PLAN', partPaymentPlan.data)
  },
  setAddressData ({ commit }, payload: { type: 'billing' | 'shipping', key: string, value: any }) {
    commit('UPDATE_ADDRESS', payload)
  },
  hasSeparateShippingAddress ({ commit }, payload: boolean) {
    commit('HAS_SEPARATE_SHIPPING_ADDRESS', payload)
  },
  saveAsDefault ({ commit }, payload: boolean) {
    commit('SAVE_AS_DEFAULT', payload)
  },
  validateSelectedPaymentMethod ({ state, dispatch }) {
    if (state.selectedPaymentMethod && !state.availablePaymentMethods.some((pm: PaymentMethod) => pm.code === state.selectedPaymentMethod?.code)) {
      dispatch('clearSelectedPaymentMethod')
    }
  },
  selectOnlyPaymentMethod ({ state, dispatch }) {
    if (state.availablePaymentMethods.length === 1) {
      dispatch('selectPaymentMethod', state.availablePaymentMethods[0])
    }
  },
  saveCustomerInfo ({ commit }, payload: CustomerInfo) {
    commit('SET_CUSTOMER_INFO', payload)
  },
  prefillUserAddresses ({ state, rootState, commit }) {
    // @ts-ignore
    if (rootState.user.loggedIn) {
      // @ts-ignore
      const defaultBillingAddress = rootState.user?.details?.addresses?.find((address: UserAddress) => address.defaultBilling)
      if (defaultBillingAddress && (JSON.stringify(state.addresses?.billing) === JSON.stringify(getClearAddress()))) {
        const mappedBillingAddress: CartAddress = {
          // @ts-ignore
          firstname: rootState.user.details.firstname,
          // @ts-ignore
          lastname: rootState.user.details.lastname,
          phone: defaultBillingAddress.phone,
          street1: defaultBillingAddress.street,
          postcode: defaultBillingAddress.postcode,
          city: defaultBillingAddress.city,
          countryCode: defaultBillingAddress.countryCode
        }
        // @ts-ignore
        const defaultShippingAddress = rootState.user?.details?.addresses?.find((address: UserAddress) => address.defaultShipping)
        if (defaultShippingAddress && (JSON.stringify(state.addresses?.shipping) === JSON.stringify(getClearAddress()))) {
          const mappedShippingAddress: CartAddress = {
            // @ts-ignore
            firstname: rootState.user.details.firstname,
            // @ts-ignore
            lastname: rootState.user.details.lastname,
            phone: defaultShippingAddress.phone,
            street1: defaultShippingAddress.street,
            postcode: defaultShippingAddress.postcode,
            city: defaultShippingAddress.city,
            countryCode: defaultShippingAddress.countryCode
          }
          commit('SET_SHIPPING_ADDRESS', mappedShippingAddress)
        }
        commit('SET_BILLING_ADDRESS', mappedBillingAddress)
        // @ts-ignore
        commit('UPDATE_USER_DATA', { key: 'email', value: rootState.user?.user?.email || '' })
      }
    }
  },
  async payOrder ({ state, rootState, rootGetters }) {
    // @ts-ignore get user id if user is logged in
    const uid = rootState.user.loggedIn && rootState.user.user ? rootState.user.user.uid : null
    // @ts-ignore
    const email = rootState.user.loggedIn && rootState.user.user ? rootState.user.user.email : null
    // @ts-ignore get cart items
    const items = rootGetters[CartGetters.items]
    // Get shippings
    const selectedShippingMethod = { ...state.selectedDeliveryMethod! }
    // @ts-ignore
    const campaign = rootState.cart.campaign
    // @ts-ignore
    const giftCard = rootState.cart.giftCard
    // @ts-ignore
    const freeItem = rootState.cart.freeItem
    const cart: PaymentCart = {
      items,
      selectedShippingMethod,
      selectedShippingMethodServicePoint: state.selectedDeliveryMethodServicePoint,
      uid,
      email,
      comment: state.comment,
      giftCard,
      selectedPaymentMethod: state.selectedPaymentMethod!,
      selectedPaymentProvider: state.selectedPaymentMethod!.code === 'KLARNA' ? 'klarna' : 'svea',
      user: state.user,
      addresses: {
        billing: state.addresses.billing,
        shipping: state.addresses.shipping
      },
      freeItem,
      partialDelivery: state.partialDelivery,
      subscribeNewsletter: state.subscribeNewsletter || false
    }
    if (campaign) {
      cart.campaign = {
        code: campaign.code
      }
    }

    const authorization = await this.$fire.auth.currentUser?.getIdToken()
    // @ts-ignore
    return this.$axios.post(this.$config.FUNCTIONS_API_URL + '/veke3000-paymentGateway-payOrderV2', { cart }, { headers: { authorization } })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.error(error)
        return false
      })
  },
  setPartialDelivery ({ commit }, payload: boolean) {
    commit('SET_PARTIAL_DELIVERY', payload)
  },
  clearCheckout ({ commit }) {
    commit('CLEAR_CHECKOUT')
  }
}

export const mutations: MutationTree<CheckoutState> = {
  SET_DYNAMIC_DELIVERY_METHODS (state, payload: DeliveryMethod[]) {
    state.dynamicDeliveryMethods = payload || []
  },
  RESET_DYNAMIC_DELIVERY_METHODS (state) {
    state.dynamicDeliveryMethods = []
  },
  SET_LAST_DELIVERY_METHOD_POSTCODE (state, payload: string) {
    state.lastDeliveryMethodPostcode = payload
  },
  SET_LAST_SUCCESSFULL_DELIVERY_METHOD_POSTCODE (state, payload: string) {
    state.lastSuccessfullDeliveryMethodPostcode = payload
  },
  SET_DELIVERY_METHOD_FETCH_ERROR (state, payload: DeliveryMethodQueryResult) {
    state.deliveryMethodFetchError = payload
  },
  SET_SELECTED_DELIVERY_METHOD (state, payload: DeliveryMethod | null) {
    state.selectedDeliveryMethod = payload
  },
  IS_FETCHING_DELIVERY_METHODS (state, payload: boolean) {
    state.isFetchingDeliveryMethods = payload
  },
  SET_AVAILABLE_DELIVERY_METHOD_SERVICE_POINTS (state, payload: DeliveryMethodServicePointResultData[]) {
    state.deliveryMethodServicePoints = payload
  },
  IS_FETCHING_DELIVERY_METHOD_SERVICE_POINTS (state, payload: boolean) {
    state.isFetchingDeliveryMethodServicePoints = payload
  },
  SET_DELIVERY_METHOD_SERVICE_POINT_FETCH_ERRORS (state, payload: DeliveryMethodServicePointQueryResult[]) {
    state.deliveryMethodServicePointFetchErrors = payload
  },
  RESET_AVAILABLE_DELIVERY_METHOD_SERVICE_POINTS (state) {
    state.deliveryMethodServicePoints = []
  },
  SET_SELECTED_DELIVERY_SERVICE_POINT (state, payload: DeliveryMethodServicePoint | null) {
    state.selectedDeliveryMethodServicePoint = payload
  },
  SET_DELIVERY_METHOD_DISCOUNTS (state, payload: DeliveryMethodDiscountRule[]) {
    state.deliveryMethodDiscountRules = payload
  },
  IS_FETCHING_DELIVERY_METHOD_DISCOUNTS (state, payload: boolean) {
    state.isFetchingDeliveryMethodDiscountRules = payload
  },
  SET_SELECTED_PAYMENT_METHOD (state, payload: PaymentMethod | null) {
    state.selectedPaymentMethod = payload
  },
  SET_PAYMENT_METHODS (state, payload: PaymentMethod[]) {
    state.availablePaymentMethods = payload
  },
  SET_ACTIVE_CHECKOUT_STEP_INDEX (state, payload: CheckoutStepIndex) {
    state.activeCheckoutStepIndex = payload
  },
  IS_FETCHING_PAYMENT_METHODS (state, payload: boolean) {
    state.isFetchingPaymentMethods = payload
  },
  HAS_PAYMENT_METHOD_FETCH_FAILED (state, payload: boolean) {
    state.hasPaymentMethodFetchFailed = payload
  },
  PAYMENT_METHODS_FETCHED_WITH_SUM (state, payload: number) {
    state.paymentMethodsFetchedWithSum = payload
  },
  UPDATE_ADDRESS (state, payload) {
    // @ts-ignore
    state.addresses[payload.type][payload.key] = payload.value
  },
  SET_BILLING_ADDRESS (state, payload) {
    // @ts-ignore
    state.addresses.billing = payload
  },
  SET_SHIPPING_ADDRESS (state, payload) {
    // @ts-ignore
    state.addresses.shipping = payload
  },
  SAVE_AS_DEFAULT (state, payload) {
    // @ts-ignore
    state.saveAsDefault = payload
  },
  UPDATE_USER_DATA (state, payload) {
    // @ts-ignore
    state.user[payload.key] = payload.value
  },
  HAS_SEPARATE_SHIPPING_ADDRESS (state, payload: boolean) {
    // clear shipping address if user wants to give a different shipping address
    if (payload) {
      state.addresses.shipping = getClearAddress()
    }
    state.hasSeparateShippingAddress = payload
  },
  SET_COMMENT (state, payload: string) {
    state.comment = payload
  },
  CLEAR_CHECKOUT (state) {
    Object.assign(state, getClearState())
  },
  SET_PART_PAYMENT_PLAN (state, payload: any) {
    state.partPaymentPlan = payload
  },
  SET_CUSTOMER_INFO (state, payload: CustomerInfo) {
    state.addresses = payload.addresses
    state.user = payload.user
    state.hasSeparateShippingAddress = payload.hasSeparateShippingAddress
    if (!payload.hasSeparateShippingAddress) {
      state.addresses.shipping = { ...state.addresses.billing }
    }
  },
  SET_PARTIAL_DELIVERY (state, payload: boolean) {
    state.partialDelivery = payload
  },
  SET_SUBSCRIBE_NEWSLETTER (state, payload: boolean) {
    state.subscribeNewsletter = payload
  }
}

export const getters: GetterTree<CheckoutState, RootState> = {
  getDynamicDeliveryMethods: state => state.dynamicDeliveryMethods,
  getSelectedDeliveryMethod: state => state.selectedDeliveryMethod,
  getGroupedDeliveryMethodsForCart: (_state, getters): DeliveryMethodGroup[] => {
    const { groupDeliveryMethods } = useShipping()
    return groupDeliveryMethods(getters.getDeliveryMethodsForCart)
  },
  getSelectedPaymentMethod: (state): PaymentMethod | null => state.selectedPaymentMethod,
  getGroupedPaymentMethods: state => state.allPaymentMethodGroups
    .map((pmg: PaymentMethodGroup) => (
      {
        ...pmg,
        paymentMethods: state.availablePaymentMethods.slice().filter((pm: PaymentMethod) => pmg.paymentMethodIds.includes(pm.code)).sort((a: PaymentMethod, b: PaymentMethod) => a.displayName.localeCompare(b.displayName, 'fi'))
      }))
    .filter((pmg: PaymentMethodGroupWithOptions) => pmg.paymentMethods.length),
  getGroupOfSelectedPaymentMethod: (state): PaymentMethodGroup | null => state.allPaymentMethodGroups.find(pmg => pmg.paymentMethodIds.includes(state.selectedPaymentMethod?.code || '')) || null,
  getDeliveryMethodsForCart: (state, _getters, rootState): DeliveryMethod[] => {
    const { getStaticDeliveryMethodsOfLargestCartItem } = useShipping()
    // @ts-ignore
    return [...state.dynamicDeliveryMethods, ...getStaticDeliveryMethodsOfLargestCartItem(rootState.cart.items)]
  },
  isFetchingPaymentMethods: state => state.isFetchingPaymentMethods,
  hasFetchingPaymentMethodsFailed: state => state.hasPaymentMethodFetchFailed,
  getPaymentMethodsFetchedWithSum: state => state.paymentMethodsFetchedWithSum,
  getAvailablePaymentMethods: state => state.availablePaymentMethods,
  getActiveCheckoutStepIndex: state => state.activeCheckoutStepIndex,
  getAddresses: state => state.addresses,
  getBillingAddress: (state): CartAddress => state.addresses.billing,
  getShippingAddress: (state): CartAddress => state.addresses.shipping,
  getUser: state => state.user,
  hasSeparateShippingAddress: state => state.hasSeparateShippingAddress,
  saveAsDefault: state => state.saveAsDefault,
  getComment: state => state.comment,
  getGrandTotalBeforeGiftCard: (state, _getters, _rootState, rootGetters): number => {
    const cartTotal = rootGetters[CartGetters.total] || 0
    const deliveryFee = state.selectedDeliveryMethod?.price || 0
    const couponDiscount = rootGetters[CartGetters.campaignDiscountAmount] || 0
    return cartTotal + deliveryFee - couponDiscount
  },
  getGiftCardAmountToUse: (_state, getters, _rootState, rootGetters): number => {
    const giftCardOpenAmount = rootGetters[CartGetters.giftCard]?.openAmount || 0
    const grandTotalBeforeGiftCard = getters.getGrandTotalBeforeGiftCard
    return Math.min(giftCardOpenAmount, grandTotalBeforeGiftCard)
  },
  getGrandTotal: (_state, getters): number => getters.getGrandTotalBeforeGiftCard - getters.getGiftCardAmountToUse,
  getPartPaymentPlanForCart: state => state.partPaymentPlan,
  getPartPaymentPlansSortedByContractLength: state => state.partPaymentPlan?.campaigns?.slice().sort((a: PartPaymentPlanCampaign, b: PartPaymentPlanCampaign) => a.ContractLengthInMonths - b.ContractLengthInMonths) || [],
  getPartPaymentPlanSortedByMonthlyAnnuity: state => state.partPaymentPlan?.campaigns?.slice().sort((a: PartPaymentPlanCampaign, b: PartPaymentPlanCampaign) => a.MonthlyAnnuity - b.MonthlyAnnuity) || [],
  getCheapestMonthlyAnnuityPaymentPlan: (_state, getters): PartPaymentPlanCampaign | null => {
    return getters.getPartPaymentPlanSortedByMonthlyAnnuity.length ? getters.getPartPaymentPlanSortedByMonthlyAnnuity[0] : null
  },
  getCampaignPartPaymentPlans: (state): PartPaymentPlanCampaign[] => state.partPaymentPlan?.campaigns?.filter((ppp: PartPaymentPlanCampaign) => ppp.NrOfInterestFreeMonths === ppp.ContractLengthInMonths && ppp.ContractLengthInMonths > 3) || [],
  getLongestCampaignPartPaymentPlan: (_state, getters): PartPaymentPlanCampaign | null => getters.getCampaignPartPaymentPlans.length > 0 ? getters.getCampaignPartPaymentPlans.reduce((currentHighest: PartPaymentPlanCampaign, partPayment: PartPaymentPlanCampaign) => currentHighest.ContractLengthInMonths > partPayment.ContractLengthInMonths ? currentHighest : partPayment) : null,
  getPartialDelivery: (state): boolean => state.partialDelivery,
  getSubscribeNewsletter: (state): boolean => state.subscribeNewsletter,
  getIsFetchingDeliveryMethods: (state): boolean => state.isFetchingDeliveryMethods,
  getLastDeliveryMethodPostcode: (state): string => state.lastDeliveryMethodPostcode,
  getLastSuccessfullDeliveryMethodPostcode: (state): string => state.lastSuccessfullDeliveryMethodPostcode,
  getDeliveryMethodFetchError: (state): DeliveryMethodQueryResult | null => state.deliveryMethodFetchError,
  getDeliveryMethodFetchFriendlyError: (state): string => {
    if (state.deliveryMethodFetchError) {
      switch (state.deliveryMethodFetchError.error_code) {
        case 'NO_PRICES':
          return 'Annettuun postinumeroon ei voida toimittaa'
        // other error codes here...
        default:
          return state.deliveryMethodFetchError.error || 'Tapahtui virhe!'
      }
    } else {
      return ''
    }
  },
  getDeliveryMethodServicePoints: (state): DeliveryMethodServicePointResultData[] => state.deliveryMethodServicePoints,
  getDeliveryMethodServicePointsByServiceProvider: state => (serviceProvider: ServiceProvider): DeliveryMethodServicePointResultData | null => state.deliveryMethodServicePoints?.find(dmsp => dmsp.serviceProvider === serviceProvider) || null,
  getDeliveryMethodServicePointFetchErrorByServiceProvider: state => (serviceProvider: ServiceProvider): DeliveryMethodServicePointQueryResult | null => state.deliveryMethodServicePointFetchErrors?.find(dmsp => dmsp.serviceProvider === serviceProvider) || null,
  getSelectedDeliveryMethodServicePoint: (state): DeliveryMethodServicePoint | null => state.selectedDeliveryMethodServicePoint,
  getDeliveryMethodServicePointFetchErrors: (state): DeliveryMethodServicePointQueryResult[] => state.deliveryMethodServicePointFetchErrors,
  getIsFetchingDeliveryMethodServicePoints: (state): boolean => state.isFetchingDeliveryMethodServicePoints,
  getDeliveryMethodDiscountRules: (state): DeliveryMethodDiscountRule[] | null => state.deliveryMethodDiscountRules,
  deliveryMethodDiscountAvailable: (state): boolean => state.dynamicDeliveryMethods.some((dm: DeliveryMethod) => dm.price < dm.originalPrice),
  getIsFetchingDeliveryMethodDiscountRules: (state): boolean => state.isFetchingDeliveryMethodDiscountRules
}
