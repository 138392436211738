import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState } from '~/store'
import type { CartType, OrderUpdateCart, ShippingMethod } from '~/types/cart'
import { CartGetters } from '~/store/cart'
import { useShipping } from '~/composable/useShipping'

const { getStaticDeliveryMethodsOfLargestCartItem } = useShipping()

const getClearState = () => ({
  orderItems: [],
  paymentHtmlSnippet: '',
  paymentData: {} as any,
  successOrder: null,
  comment: '',
  createdAt: null,
  selectedShippingMethod: {} as ShippingMethod,
  campaign: null,
  klarnaOrder: null,
  salesReference: null
})

export const state = () => getClearState()

export type OrderState = ReturnType<typeof state>

export enum OrderActions {
  payOrder = 'order/payOrder',
  setSelectedShippingMethod = 'order/setSelectedShippingMethod',
  clearOrder = 'order/clearOrder',
  updateOrder = 'order/updateOrder',
  setSuccessOrder = 'order/setSuccessOrder',
  setSalesReference = 'order/setSalesReference'
}

export enum OrderMutations {
  setComment = 'order/SET_COMMENT',
  clearOrder = 'order/CLEAR_ORDER',
}

export enum OrderGetters {
  klarnaSnippet = 'order/klarnaSnippet',
  comment = 'order/comment',
  createdAt = 'cart/createdAt',
  selectedShippingMethod = 'order/selectedShippingMethod',
  paymentData = 'order/paymentData',
  successOrder = 'order/successOrder',
  salesReference = 'order/salesReference'
}

export const actions: ActionTree<OrderState, RootState> = {
  payOrder ({ commit, state, rootState, rootGetters }) {
    // Reset payment data
    commit('SET_PAYMENT_DATA', {})
    // @ts-ignore get user id if user is logged in
    const uid = rootState.user.loggedIn && rootState.user.user ? rootState.user.user.uid : null
    // @ts-ignore
    const email = rootState.user.loggedIn && rootState.user.user ? rootState.user.user.email : null
    // @ts-ignore get cart items
    const items = rootGetters[CartGetters.items]
    // Get shippings
    const shippings = getStaticDeliveryMethodsOfLargestCartItem(items)
    // @ts-ignore
    const campaign = rootState.cart.campaign
    // @ts-ignore
    const giftCard = rootState.cart.giftCard
    // @ts-ignore
    const cartRule = rootState.cart.cartRule

    const cart: CartType = {
      items,
      shipping: shippings,
      uid,
      email,
      comment: state.comment,
      campaign,
      giftCard,
      cartRule
    }
    // set selected shipping method the default one, this is only used to show shipping price in summary when it loads
    const seletedShippingMethod = { ...shippings[0] }
    seletedShippingMethod.price = seletedShippingMethod.price * 100
    commit('SET_SELECTED_SHIPPING_METHOD', seletedShippingMethod)
    // @ts-ignore send order to backend
    const payOrder = this.$fire.functions.httpsCallable('veke3000-payment-payOrder')
    return payOrder({ cart })
      .then((res) => {
        commit('SET_PAYMENT_DATA', res.data.response)
        if (res.data.statusCode === 200) {
          return true
        } else {
          console.error('An error occured while creating an order:', res.data.response)
          return false
        }
      })
      .catch((err) => {
        // @TODO: Add error handling
        // An unhandled error occured
        console.error('payOrder-call failed:', err)
        return false
      })
  },
  setSelectedShippingMethod ({ commit }, payload) {
    commit('SET_SELECTED_SHIPPING_METHOD', payload)
  },
  clearOrder ({ commit }) {
    commit('CLEAR_ORDER')
  },
  updateOrder ({ state, rootState, rootGetters }, payload: any) {
    // check that selected shipping option is not one that is now selected
    if (state.selectedShippingMethod.id !== payload.id) {
      // @ts-ignore get cart items
      const items = rootGetters[CartGetters.items]
      // @ts-ignore
      const campaign = rootState.cart.campaign
      // @ts-ignore
      const giftCard = rootState.cart.giftCard
      const cart: OrderUpdateCart = {
        items,
        campaign,
        giftCard
      }
      // update order lines in klarna order data
      // @ts-ignore send order to backend
      const payOrder = this.$fire.functions.httpsCallable('veke3000-payment-payOrder')
      const updatedOrder = updateOrderLines(state.paymentData)
      const response = payOrder({ cart, order: updatedOrder })
      console.log(response)
    }
  },
  setSuccessOrder ({ commit }, payload) {
    commit('SUCCESS_ORDER', payload)
  },
  setSalesReference ({ commit }, payload) {
    commit('SALES_REFERENCE', payload)
  }
}

export const mutations: MutationTree<OrderState> = {
  SET_PAYMENT_DATA (state, payload: any) {
    state.paymentData = payload
  },
  SET_HTML_SNIPPET (state, payload: string) {
    state.paymentHtmlSnippet = payload
  },
  SET_COMMENT (state, payload: string) {
    state.comment = payload
  },
  SET_CREATED_AT (state) {
    // @ts-ignore
    state.createdAt = new Date()
  },
  SET_SELECTED_SHIPPING_METHOD (state, payload: ShippingMethod) {
    state.selectedShippingMethod = payload
  },
  CLEAR_ORDER (state) {
    Object.assign(state, getClearState())
  },
  SUCCESS_ORDER (state, payload) {
    state.successOrder = payload
  },
  SALES_REFERENCE (state, payload) {
    state.salesReference = payload
  }
}

export const getters: GetterTree<OrderState, RootState> = {
  paymentHtmlSnippet: state => state.paymentHtmlSnippet,
  paymentData: state => state.paymentData,
  klarnaSnippet: state => state.paymentData.html_snippet,
  comment: (state): string => state.comment,
  createdAt: (state): Date | null => state.createdAt,
  selectedShippingMethod: (state): ShippingMethod => state.selectedShippingMethod,
  successOrder: state => state.successOrder,
  salesReference: state => state.salesReference
}

function updateOrderLines (klarnaOrderData: any) {
  console.log('update klarna order: ', klarnaOrderData)
  return klarnaOrderData
}
