export const AccordionGroup = () => import('../../components/AccordionGroup.vue' /* webpackChunkName: "components/accordion-group" */).then(c => wrapFunctional(c.default || c))
export const AddToCart = () => import('../../components/AddToCart.vue' /* webpackChunkName: "components/add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const AddToWishlist = () => import('../../components/AddToWishlist.vue' /* webpackChunkName: "components/add-to-wishlist" */).then(c => wrapFunctional(c.default || c))
export const Banner = () => import('../../components/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const BaseAccordion = () => import('../../components/BaseAccordion.vue' /* webpackChunkName: "components/base-accordion" */).then(c => wrapFunctional(c.default || c))
export const BaseButton = () => import('../../components/BaseButton.vue' /* webpackChunkName: "components/base-button" */).then(c => wrapFunctional(c.default || c))
export const BaseCheckbox = () => import('../../components/BaseCheckbox.vue' /* webpackChunkName: "components/base-checkbox" */).then(c => wrapFunctional(c.default || c))
export const BaseCheckboxGroup = () => import('../../components/BaseCheckboxGroup.vue' /* webpackChunkName: "components/base-checkbox-group" */).then(c => wrapFunctional(c.default || c))
export const BaseDropdown = () => import('../../components/BaseDropdown.vue' /* webpackChunkName: "components/base-dropdown" */).then(c => wrapFunctional(c.default || c))
export const BaseInput = () => import('../../components/BaseInput.vue' /* webpackChunkName: "components/base-input" */).then(c => wrapFunctional(c.default || c))
export const BaseModal = () => import('../../components/BaseModal.vue' /* webpackChunkName: "components/base-modal" */).then(c => wrapFunctional(c.default || c))
export const BasePagination = () => import('../../components/BasePagination.vue' /* webpackChunkName: "components/base-pagination" */).then(c => wrapFunctional(c.default || c))
export const BaseRadio = () => import('../../components/BaseRadio.vue' /* webpackChunkName: "components/base-radio" */).then(c => wrapFunctional(c.default || c))
export const BaseSelect = () => import('../../components/BaseSelect.vue' /* webpackChunkName: "components/base-select" */).then(c => wrapFunctional(c.default || c))
export const BaseStepper = () => import('../../components/BaseStepper.vue' /* webpackChunkName: "components/base-stepper" */).then(c => wrapFunctional(c.default || c))
export const BaseTag = () => import('../../components/BaseTag.vue' /* webpackChunkName: "components/base-tag" */).then(c => wrapFunctional(c.default || c))
export const BaseTextarea = () => import('../../components/BaseTextarea.vue' /* webpackChunkName: "components/base-textarea" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumbs = () => import('../../components/Breadcrumbs.vue' /* webpackChunkName: "components/breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const ContentfulProductTile = () => import('../../components/ContentfulProductTile.vue' /* webpackChunkName: "components/contentful-product-tile" */).then(c => wrapFunctional(c.default || c))
export const CustomerSatisfactionSurvey = () => import('../../components/CustomerSatisfactionSurvey.vue' /* webpackChunkName: "components/customer-satisfaction-survey" */).then(c => wrapFunctional(c.default || c))
export const DeliveryMethodSearch = () => import('../../components/DeliveryMethodSearch.vue' /* webpackChunkName: "components/delivery-method-search" */).then(c => wrapFunctional(c.default || c))
export const DropperMenu = () => import('../../components/DropperMenu.vue' /* webpackChunkName: "components/dropper-menu" */).then(c => wrapFunctional(c.default || c))
export const EmailField = () => import('../../components/EmailField.vue' /* webpackChunkName: "components/email-field" */).then(c => wrapFunctional(c.default || c))
export const HeadingLink = () => import('../../components/HeadingLink.vue' /* webpackChunkName: "components/heading-link" */).then(c => wrapFunctional(c.default || c))
export const HeroContent = () => import('../../components/HeroContent.vue' /* webpackChunkName: "components/hero-content" */).then(c => wrapFunctional(c.default || c))
export const ImageLabelNavigation = () => import('../../components/ImageLabelNavigation.vue' /* webpackChunkName: "components/image-label-navigation" */).then(c => wrapFunctional(c.default || c))
export const ImageLink = () => import('../../components/ImageLink.vue' /* webpackChunkName: "components/image-link" */).then(c => wrapFunctional(c.default || c))
export const ImageLinkWithLabel = () => import('../../components/ImageLinkWithLabel.vue' /* webpackChunkName: "components/image-link-with-label" */).then(c => wrapFunctional(c.default || c))
export const PasswordField = () => import('../../components/PasswordField.vue' /* webpackChunkName: "components/password-field" */).then(c => wrapFunctional(c.default || c))
export const PostcodeInput = () => import('../../components/PostcodeInput.vue' /* webpackChunkName: "components/postcode-input" */).then(c => wrapFunctional(c.default || c))
export const SearchField = () => import('../../components/SearchField.vue' /* webpackChunkName: "components/search-field" */).then(c => wrapFunctional(c.default || c))
export const ServicePointSearch = () => import('../../components/ServicePointSearch.vue' /* webpackChunkName: "components/service-point-search" */).then(c => wrapFunctional(c.default || c))
export const ServicePointSelect = () => import('../../components/ServicePointSelect.vue' /* webpackChunkName: "components/service-point-select" */).then(c => wrapFunctional(c.default || c))
export const ShareCart = () => import('../../components/ShareCart.vue' /* webpackChunkName: "components/share-cart" */).then(c => wrapFunctional(c.default || c))
export const SiteReviews = () => import('../../components/SiteReviews.vue' /* webpackChunkName: "components/site-reviews" */).then(c => wrapFunctional(c.default || c))
export const SkipToContent = () => import('../../components/SkipToContent.vue' /* webpackChunkName: "components/skip-to-content" */).then(c => wrapFunctional(c.default || c))
export const TitleAndNavigation = () => import('../../components/TitleAndNavigation.vue' /* webpackChunkName: "components/title-and-navigation" */).then(c => wrapFunctional(c.default || c))
export const VekeLogo = () => import('../../components/VekeLogo.vue' /* webpackChunkName: "components/veke-logo" */).then(c => wrapFunctional(c.default || c))
export const VekePagination = () => import('../../components/VekePagination.vue' /* webpackChunkName: "components/veke-pagination" */).then(c => wrapFunctional(c.default || c))
export const AccountAddressForm = () => import('../../components/account/AddressForm.vue' /* webpackChunkName: "components/account-address-form" */).then(c => wrapFunctional(c.default || c))
export const AccountAddresses = () => import('../../components/account/Addresses.vue' /* webpackChunkName: "components/account-addresses" */).then(c => wrapFunctional(c.default || c))
export const AccountChangeEmailForm = () => import('../../components/account/ChangeEmailForm.vue' /* webpackChunkName: "components/account-change-email-form" */).then(c => wrapFunctional(c.default || c))
export const AccountChangePasswordForm = () => import('../../components/account/ChangePasswordForm.vue' /* webpackChunkName: "components/account-change-password-form" */).then(c => wrapFunctional(c.default || c))
export const AccountEmailVerification = () => import('../../components/account/EmailVerification.vue' /* webpackChunkName: "components/account-email-verification" */).then(c => wrapFunctional(c.default || c))
export const AccountErrorMessage = () => import('../../components/account/ErrorMessage.vue' /* webpackChunkName: "components/account-error-message" */).then(c => wrapFunctional(c.default || c))
export const AccountForgotPasswordEmailForm = () => import('../../components/account/ForgotPasswordEmailForm.vue' /* webpackChunkName: "components/account-forgot-password-email-form" */).then(c => wrapFunctional(c.default || c))
export const AccountForgotPasswordResetForm = () => import('../../components/account/ForgotPasswordResetForm.vue' /* webpackChunkName: "components/account-forgot-password-reset-form" */).then(c => wrapFunctional(c.default || c))
export const AccountInvalidAuthLink = () => import('../../components/account/InvalidAuthLink.vue' /* webpackChunkName: "components/account-invalid-auth-link" */).then(c => wrapFunctional(c.default || c))
export const AccountLoginEmail = () => import('../../components/account/LoginEmail.vue' /* webpackChunkName: "components/account-login-email" */).then(c => wrapFunctional(c.default || c))
export const AccountLoginPassword = () => import('../../components/account/LoginPassword.vue' /* webpackChunkName: "components/account-login-password" */).then(c => wrapFunctional(c.default || c))
export const AccountMarketingPermissionsForm = () => import('../../components/account/MarketingPermissionsForm.vue' /* webpackChunkName: "components/account-marketing-permissions-form" */).then(c => wrapFunctional(c.default || c))
export const AccountPageHeading = () => import('../../components/account/PageHeading.vue' /* webpackChunkName: "components/account-page-heading" */).then(c => wrapFunctional(c.default || c))
export const AccountSidebar = () => import('../../components/account/Sidebar.vue' /* webpackChunkName: "components/account-sidebar" */).then(c => wrapFunctional(c.default || c))
export const AccountUserAddress = () => import('../../components/account/UserAddress.vue' /* webpackChunkName: "components/account-user-address" */).then(c => wrapFunctional(c.default || c))
export const AccountUserDetails = () => import('../../components/account/UserDetails.vue' /* webpackChunkName: "components/account-user-details" */).then(c => wrapFunctional(c.default || c))
export const AccountUserDetailsForm = () => import('../../components/account/UserDetailsForm.vue' /* webpackChunkName: "components/account-user-details-form" */).then(c => wrapFunctional(c.default || c))
export const AccountVerifyEmail = () => import('../../components/account/VerifyEmail.vue' /* webpackChunkName: "components/account-verify-email" */).then(c => wrapFunctional(c.default || c))
export const B2bContactBusinessSales = () => import('../../components/b2b/ContactBusinessSales.vue' /* webpackChunkName: "components/b2b-contact-business-sales" */).then(c => wrapFunctional(c.default || c))
export const B2bContactBusinessSalesMultipleFaces = () => import('../../components/b2b/ContactBusinessSalesMultipleFaces.vue' /* webpackChunkName: "components/b2b-contact-business-sales-multiple-faces" */).then(c => wrapFunctional(c.default || c))
export const B2bProductBrochures = () => import('../../components/b2b/ProductBrochures.vue' /* webpackChunkName: "components/b2b-product-brochures" */).then(c => wrapFunctional(c.default || c))
export const CampaignCategoryBanner = () => import('../../components/campaign/CampaignCategoryBanner.vue' /* webpackChunkName: "components/campaign-category-banner" */).then(c => wrapFunctional(c.default || c))
export const CampaignCopyText = () => import('../../components/campaign/CampaignCopyText.vue' /* webpackChunkName: "components/campaign-copy-text" */).then(c => wrapFunctional(c.default || c))
export const CampaignHeroBanner = () => import('../../components/campaign/CampaignHeroBanner.vue' /* webpackChunkName: "components/campaign-hero-banner" */).then(c => wrapFunctional(c.default || c))
export const CampaignProductTileBanner = () => import('../../components/campaign/CampaignProductTileBanner.vue' /* webpackChunkName: "components/campaign-product-tile-banner" */).then(c => wrapFunctional(c.default || c))
export const CampaignTileBanners = () => import('../../components/campaign/CampaignTileBanners.vue' /* webpackChunkName: "components/campaign-tile-banners" */).then(c => wrapFunctional(c.default || c))
export const CampaignCartRuleCampaignBanner = () => import('../../components/campaign/CartRuleCampaignBanner.vue' /* webpackChunkName: "components/campaign-cart-rule-campaign-banner" */).then(c => wrapFunctional(c.default || c))
export const CampaignCartRuleConditionsModal = () => import('../../components/campaign/CartRuleConditionsModal.vue' /* webpackChunkName: "components/campaign-cart-rule-conditions-modal" */).then(c => wrapFunctional(c.default || c))
export const CampaignCartRuleDynamicBanner = () => import('../../components/campaign/CartRuleDynamicBanner.vue' /* webpackChunkName: "components/campaign-cart-rule-dynamic-banner" */).then(c => wrapFunctional(c.default || c))
export const Blog = () => import('../../components/blog/Blog.vue' /* webpackChunkName: "components/blog" */).then(c => wrapFunctional(c.default || c))
export const BlogHighlightTile = () => import('../../components/blog/BlogHighlightTile.vue' /* webpackChunkName: "components/blog-highlight-tile" */).then(c => wrapFunctional(c.default || c))
export const BlogHighlights = () => import('../../components/blog/BlogHighlights.vue' /* webpackChunkName: "components/blog-highlights" */).then(c => wrapFunctional(c.default || c))
export const BlogLink = () => import('../../components/blog/BlogLink.vue' /* webpackChunkName: "components/blog-link" */).then(c => wrapFunctional(c.default || c))
export const BlogLinks = () => import('../../components/blog/BlogLinks.vue' /* webpackChunkName: "components/blog-links" */).then(c => wrapFunctional(c.default || c))
export const CartAddCampaignCode = () => import('../../components/cart/AddCampaignCode.vue' /* webpackChunkName: "components/cart-add-campaign-code" */).then(c => wrapFunctional(c.default || c))
export const CartAddGiftCard = () => import('../../components/cart/AddGiftCard.vue' /* webpackChunkName: "components/cart-add-gift-card" */).then(c => wrapFunctional(c.default || c))
export const CartBuyAlsoProducts = () => import('../../components/cart/BuyAlsoProducts.vue' /* webpackChunkName: "components/cart-buy-also-products" */).then(c => wrapFunctional(c.default || c))
export const CartItem = () => import('../../components/cart/CartItem.vue' /* webpackChunkName: "components/cart-item" */).then(c => wrapFunctional(c.default || c))
export const CartItems = () => import('../../components/cart/CartItems.vue' /* webpackChunkName: "components/cart-items" */).then(c => wrapFunctional(c.default || c))
export const CartSummary = () => import('../../components/cart/CartSummary.vue' /* webpackChunkName: "components/cart-summary" */).then(c => wrapFunctional(c.default || c))
export const CartValidator = () => import('../../components/cart/CartValidator.vue' /* webpackChunkName: "components/cart-validator" */).then(c => wrapFunctional(c.default || c))
export const CartClubSavings = () => import('../../components/cart/ClubSavings.vue' /* webpackChunkName: "components/cart-club-savings" */).then(c => wrapFunctional(c.default || c))
export const CartNoDeliveryMethodsFound = () => import('../../components/cart/NoDeliveryMethodsFound.vue' /* webpackChunkName: "components/cart-no-delivery-methods-found" */).then(c => wrapFunctional(c.default || c))
export const CartOrderComment = () => import('../../components/cart/OrderComment.vue' /* webpackChunkName: "components/cart-order-comment" */).then(c => wrapFunctional(c.default || c))
export const CartProductTileQuickAdd = () => import('../../components/cart/ProductTileQuickAdd.vue' /* webpackChunkName: "components/cart-product-tile-quick-add" */).then(c => wrapFunctional(c.default || c))
export const CartRejectedPaymentInfo = () => import('../../components/cart/RejectedPaymentInfo.vue' /* webpackChunkName: "components/cart-rejected-payment-info" */).then(c => wrapFunctional(c.default || c))
export const CartSharedCartItem = () => import('../../components/cart/SharedCartItem.vue' /* webpackChunkName: "components/cart-shared-cart-item" */).then(c => wrapFunctional(c.default || c))
export const CartShippingSummary = () => import('../../components/cart/ShippingSummary.vue' /* webpackChunkName: "components/cart-shipping-summary" */).then(c => wrapFunctional(c.default || c))
export const CartSveaWidget = () => import('../../components/cart/SveaWidget.vue' /* webpackChunkName: "components/cart-svea-widget" */).then(c => wrapFunctional(c.default || c))
export const CartSveaWidgetModal = () => import('../../components/cart/SveaWidgetModal.vue' /* webpackChunkName: "components/cart-svea-widget-modal" */).then(c => wrapFunctional(c.default || c))
export const Category = () => import('../../components/category/Category.vue' /* webpackChunkName: "components/category" */).then(c => wrapFunctional(c.default || c))
export const CategoryBodyText = () => import('../../components/category/CategoryBodyText.vue' /* webpackChunkName: "components/category-body-text" */).then(c => wrapFunctional(c.default || c))
export const CategoryFaq = () => import('../../components/category/CategoryFaq.vue' /* webpackChunkName: "components/category-faq" */).then(c => wrapFunctional(c.default || c))
export const CategoryHeader = () => import('../../components/category/CategoryHeader.vue' /* webpackChunkName: "components/category-header" */).then(c => wrapFunctional(c.default || c))
export const CategoryNotFound = () => import('../../components/category/CategoryNotFound.vue' /* webpackChunkName: "components/category-not-found" */).then(c => wrapFunctional(c.default || c))
export const CategoryImageSelector = () => import('../../components/category/ImageSelector.vue' /* webpackChunkName: "components/category-image-selector" */).then(c => wrapFunctional(c.default || c))
export const CategoryProductListSorter = () => import('../../components/category/ProductListSorter.vue' /* webpackChunkName: "components/category-product-list-sorter" */).then(c => wrapFunctional(c.default || c))
export const CategoryProductTile = () => import('../../components/category/ProductTile.vue' /* webpackChunkName: "components/category-product-tile" */).then(c => wrapFunctional(c.default || c))
export const CategoryProductTileMini = () => import('../../components/category/ProductTileMini.vue' /* webpackChunkName: "components/category-product-tile-mini" */).then(c => wrapFunctional(c.default || c))
export const CategoryProductTileMiniSkeleton = () => import('../../components/category/ProductTileMiniSkeleton.vue' /* webpackChunkName: "components/category-product-tile-mini-skeleton" */).then(c => wrapFunctional(c.default || c))
export const ClubTermsModal = () => import('../../components/club/ClubTermsModal.vue' /* webpackChunkName: "components/club-terms-modal" */).then(c => wrapFunctional(c.default || c))
export const ClubJoinClubForm = () => import('../../components/club/JoinClubForm.vue' /* webpackChunkName: "components/club-join-club-form" */).then(c => wrapFunctional(c.default || c))
export const ClubLeaveClubModal = () => import('../../components/club/LeaveClubModal.vue' /* webpackChunkName: "components/club-leave-club-modal" */).then(c => wrapFunctional(c.default || c))
export const CheckoutStepper = () => import('../../components/checkout/CheckoutStepper.vue' /* webpackChunkName: "components/checkout-stepper" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSteps = () => import('../../components/checkout/CheckoutSteps.vue' /* webpackChunkName: "components/checkout-steps" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSelectionDropdown = () => import('../../components/checkout/SelectionDropdown.vue' /* webpackChunkName: "components/checkout-selection-dropdown" */).then(c => wrapFunctional(c.default || c))
export const ContentAboutUsHomepage = () => import('../../components/content/AboutUsHomepage.vue' /* webpackChunkName: "components/content-about-us-homepage" */).then(c => wrapFunctional(c.default || c))
export const ContentCompanyPageNavigation = () => import('../../components/content/CompanyPageNavigation.vue' /* webpackChunkName: "components/content-company-page-navigation" */).then(c => wrapFunctional(c.default || c))
export const ContentCustomerServicePageNavigation = () => import('../../components/content/CustomerServicePageNavigation.vue' /* webpackChunkName: "components/content-customer-service-page-navigation" */).then(c => wrapFunctional(c.default || c))
export const ContentJointBlockA = () => import('../../components/content/JointBlockA.vue' /* webpackChunkName: "components/content-joint-block-a" */).then(c => wrapFunctional(c.default || c))
export const ContentJointBlockB = () => import('../../components/content/JointBlockB.vue' /* webpackChunkName: "components/content-joint-block-b" */).then(c => wrapFunctional(c.default || c))
export const ContentVekeXDesigner = () => import('../../components/content/VekeXDesigner.vue' /* webpackChunkName: "components/content-veke-x-designer" */).then(c => wrapFunctional(c.default || c))
export const EmployeesUserProfile = () => import('../../components/employees/UserProfile.vue' /* webpackChunkName: "components/employees-user-profile" */).then(c => wrapFunctional(c.default || c))
export const FrontpageCategoryBrandSelector = () => import('../../components/frontpage/CategoryBrandSelector.vue' /* webpackChunkName: "components/frontpage-category-brand-selector" */).then(c => wrapFunctional(c.default || c))
export const FrontpagePopularCategories = () => import('../../components/frontpage/PopularCategories.vue' /* webpackChunkName: "components/frontpage-popular-categories" */).then(c => wrapFunctional(c.default || c))
export const GlobalMessageCountdown = () => import('../../components/global-message/Countdown.vue' /* webpackChunkName: "components/global-message-countdown" */).then(c => wrapFunctional(c.default || c))
export const GlobalMessage = () => import('../../components/global-message/GlobalMessage.vue' /* webpackChunkName: "components/global-message" */).then(c => wrapFunctional(c.default || c))
export const ModalsVideoModal = () => import('../../components/modals/VideoModal.vue' /* webpackChunkName: "components/modals-video-modal" */).then(c => wrapFunctional(c.default || c))
export const PageMessage = () => import('../../components/page-message/PageMessage.vue' /* webpackChunkName: "components/page-message" */).then(c => wrapFunctional(c.default || c))
export const PageMessages = () => import('../../components/page-message/PageMessages.vue' /* webpackChunkName: "components/page-messages" */).then(c => wrapFunctional(c.default || c))
export const ProductDeliveryInfo = () => import('../../components/product/DeliveryInfo.vue' /* webpackChunkName: "components/product-delivery-info" */).then(c => wrapFunctional(c.default || c))
export const ProductDownsellProducts = () => import('../../components/product/DownsellProducts.vue' /* webpackChunkName: "components/product-downsell-products" */).then(c => wrapFunctional(c.default || c))
export const ProductLinkedOutletProduct = () => import('../../components/product/LinkedOutletProduct.vue' /* webpackChunkName: "components/product-linked-outlet-product" */).then(c => wrapFunctional(c.default || c))
export const Product = () => import('../../components/product/Product.vue' /* webpackChunkName: "components/product" */).then(c => wrapFunctional(c.default || c))
export const ProductAttachments = () => import('../../components/product/ProductAttachments.vue' /* webpackChunkName: "components/product-attachments" */).then(c => wrapFunctional(c.default || c))
export const ProductLabels = () => import('../../components/product/ProductLabels.vue' /* webpackChunkName: "components/product-labels" */).then(c => wrapFunctional(c.default || c))
export const ProductMeasurements = () => import('../../components/product/ProductMeasurements.vue' /* webpackChunkName: "components/product-measurements" */).then(c => wrapFunctional(c.default || c))
export const ProductNotFound = () => import('../../components/product/ProductNotFound.vue' /* webpackChunkName: "components/product-not-found" */).then(c => wrapFunctional(c.default || c))
export const ProductOptions = () => import('../../components/product/ProductOptions.vue' /* webpackChunkName: "components/product-options" */).then(c => wrapFunctional(c.default || c))
export const ProductPriceDescription = () => import('../../components/product/ProductPriceDescription.vue' /* webpackChunkName: "components/product-price-description" */).then(c => wrapFunctional(c.default || c))
export const ProductPriceHistory = () => import('../../components/product/ProductPriceHistory.vue' /* webpackChunkName: "components/product-price-history" */).then(c => wrapFunctional(c.default || c))
export const ProductPriceLabel = () => import('../../components/product/ProductPriceLabel.vue' /* webpackChunkName: "components/product-price-label" */).then(c => wrapFunctional(c.default || c))
export const ProductSeriesProducts = () => import('../../components/product/ProductSeriesProducts.vue' /* webpackChunkName: "components/product-series-products" */).then(c => wrapFunctional(c.default || c))
export const ProductStartingPriceLabel = () => import('../../components/product/ProductStartingPriceLabel.vue' /* webpackChunkName: "components/product-starting-price-label" */).then(c => wrapFunctional(c.default || c))
export const ProductTechnicalSpecifications = () => import('../../components/product/ProductTechnicalSpecifications.vue' /* webpackChunkName: "components/product-technical-specifications" */).then(c => wrapFunctional(c.default || c))
export const ProductRecentlyViewedProduct = () => import('../../components/product/RecentlyViewedProduct.vue' /* webpackChunkName: "components/product-recently-viewed-product" */).then(c => wrapFunctional(c.default || c))
export const ProductRecentlyViewedProductSkeleton = () => import('../../components/product/RecentlyViewedProductSkeleton.vue' /* webpackChunkName: "components/product-recently-viewed-product-skeleton" */).then(c => wrapFunctional(c.default || c))
export const ProductRecentlyViewedProducts = () => import('../../components/product/RecentlyViewedProducts.vue' /* webpackChunkName: "components/product-recently-viewed-products" */).then(c => wrapFunctional(c.default || c))
export const ProductRelatedProducts = () => import('../../components/product/RelatedProducts.vue' /* webpackChunkName: "components/product-related-products" */).then(c => wrapFunctional(c.default || c))
export const ProductReviewStars = () => import('../../components/product/ReviewStars.vue' /* webpackChunkName: "components/product-review-stars" */).then(c => wrapFunctional(c.default || c))
export const ProductStockStatus = () => import('../../components/product/StockStatus.vue' /* webpackChunkName: "components/product-stock-status" */).then(c => wrapFunctional(c.default || c))
export const ProductSupplementProduct = () => import('../../components/product/SupplementProduct.vue' /* webpackChunkName: "components/product-supplement-product" */).then(c => wrapFunctional(c.default || c))
export const ProductSupplementProducts = () => import('../../components/product/SupplementProducts.vue' /* webpackChunkName: "components/product-supplement-products" */).then(c => wrapFunctional(c.default || c))
export const ProductSveaPartPaymentCalculator = () => import('../../components/product/SveaPartPaymentCalculator.vue' /* webpackChunkName: "components/product-svea-part-payment-calculator" */).then(c => wrapFunctional(c.default || c))
export const ProductSveaWidget = () => import('../../components/product/SveaWidget.vue' /* webpackChunkName: "components/product-svea-widget" */).then(c => wrapFunctional(c.default || c))
export const ProductSynonymProducts = () => import('../../components/product/SynonymProducts.vue' /* webpackChunkName: "components/product-synonym-products" */).then(c => wrapFunctional(c.default || c))
export const ProductUpsellProducts = () => import('../../components/product/UpsellProducts.vue' /* webpackChunkName: "components/product-upsell-products" */).then(c => wrapFunctional(c.default || c))
export const ProductListItem = () => import('../../components/productReturn/ProductListItem.vue' /* webpackChunkName: "components/product-list-item" */).then(c => wrapFunctional(c.default || c))
export const SearchItemLink = () => import('../../components/search/SearchItemLink.vue' /* webpackChunkName: "components/search-item-link" */).then(c => wrapFunctional(c.default || c))
export const SearchItemProduct = () => import('../../components/search/SearchItemProduct.vue' /* webpackChunkName: "components/search-item-product" */).then(c => wrapFunctional(c.default || c))
export const SearchPanel = () => import('../../components/search/SearchPanel.vue' /* webpackChunkName: "components/search-panel" */).then(c => wrapFunctional(c.default || c))
export const SiteFooterBaseFooter = () => import('../../components/site-footer/BaseFooter.vue' /* webpackChunkName: "components/site-footer-base-footer" */).then(c => wrapFunctional(c.default || c))
export const SiteFooterCustomerServiceInfo = () => import('../../components/site-footer/CustomerServiceInfo.vue' /* webpackChunkName: "components/site-footer-customer-service-info" */).then(c => wrapFunctional(c.default || c))
export const SiteFooter = () => import('../../components/site-footer/SiteFooter.vue' /* webpackChunkName: "components/site-footer" */).then(c => wrapFunctional(c.default || c))
export const SiteFooterSocialMediaLinks = () => import('../../components/site-footer/SocialMediaLinks.vue' /* webpackChunkName: "components/site-footer-social-media-links" */).then(c => wrapFunctional(c.default || c))
export const SiteHeaderBenefitBar = () => import('../../components/site-header/BenefitBar.vue' /* webpackChunkName: "components/site-header-benefit-bar" */).then(c => wrapFunctional(c.default || c))
export const SiteHeader = () => import('../../components/site-header/SiteHeader.vue' /* webpackChunkName: "components/site-header" */).then(c => wrapFunctional(c.default || c))
export const StockProductStock = () => import('../../components/stock/ProductStock.vue' /* webpackChunkName: "components/stock-product-stock" */).then(c => wrapFunctional(c.default || c))
export const StockProductStoreAvailability = () => import('../../components/stock/ProductStoreAvailability.vue' /* webpackChunkName: "components/stock-product-store-availability" */).then(c => wrapFunctional(c.default || c))
export const StockProductStoreAvailabilityModal = () => import('../../components/stock/ProductStoreAvailabilityModal.vue' /* webpackChunkName: "components/stock-product-store-availability-modal" */).then(c => wrapFunctional(c.default || c))
export const StoresStoreCard = () => import('../../components/stores/StoreCard.vue' /* webpackChunkName: "components/stores-store-card" */).then(c => wrapFunctional(c.default || c))
export const StoresStoreFaq = () => import('../../components/stores/StoreFaq.vue' /* webpackChunkName: "components/stores-store-faq" */).then(c => wrapFunctional(c.default || c))
export const StoresStorePage = () => import('../../components/stores/StorePage.vue' /* webpackChunkName: "components/stores-store-page" */).then(c => wrapFunctional(c.default || c))
export const ToastNotification = () => import('../../components/toast-notifications/ToastNotification.vue' /* webpackChunkName: "components/toast-notification" */).then(c => wrapFunctional(c.default || c))
export const ToastNotifications = () => import('../../components/toast-notifications/ToastNotifications.vue' /* webpackChunkName: "components/toast-notifications" */).then(c => wrapFunctional(c.default || c))
export const VekeCarousel = () => import('../../components/veke-carousel/VekeCarousel.vue' /* webpackChunkName: "components/veke-carousel" */).then(c => wrapFunctional(c.default || c))
export const VekeCarouselArrows = () => import('../../components/veke-carousel/VekeCarouselArrows.vue' /* webpackChunkName: "components/veke-carousel-arrows" */).then(c => wrapFunctional(c.default || c))
export const VideobotProductTile = () => import('../../components/videobot/VideobotProductTile.vue' /* webpackChunkName: "components/videobot-product-tile" */).then(c => wrapFunctional(c.default || c))
export const VideobotSlider = () => import('../../components/videobot/VideobotSlider.vue' /* webpackChunkName: "components/videobot-slider" */).then(c => wrapFunctional(c.default || c))
export const VueInstantsearchCacheResults = () => import('../../components/vue-instantsearch/CacheResults.vue' /* webpackChunkName: "components/vue-instantsearch-cache-results" */).then(c => wrapFunctional(c.default || c))
export const VueInstantsearchSearchBox = () => import('../../components/vue-instantsearch/SearchBox.vue' /* webpackChunkName: "components/vue-instantsearch-search-box" */).then(c => wrapFunctional(c.default || c))
export const VueInstantsearchStateResults = () => import('../../components/vue-instantsearch/StateResults.vue' /* webpackChunkName: "components/vue-instantsearch-state-results" */).then(c => wrapFunctional(c.default || c))
export const VueInstantsearchStats = () => import('../../components/vue-instantsearch/Stats.vue' /* webpackChunkName: "components/vue-instantsearch-stats" */).then(c => wrapFunctional(c.default || c))
export const VueInstantsearchVAisHits = () => import('../../components/vue-instantsearch/VAisHits.vue' /* webpackChunkName: "components/vue-instantsearch-v-ais-hits" */).then(c => wrapFunctional(c.default || c))
export const WishlistItem = () => import('../../components/wishlist/WishlistItem.vue' /* webpackChunkName: "components/wishlist-item" */).then(c => wrapFunctional(c.default || c))
export const WishlistItems = () => import('../../components/wishlist/WishlistItems.vue' /* webpackChunkName: "components/wishlist-items" */).then(c => wrapFunctional(c.default || c))
export const WishlistOverview = () => import('../../components/wishlist/WishlistOverview.vue' /* webpackChunkName: "components/wishlist-overview" */).then(c => wrapFunctional(c.default || c))
export const YotpoReviews = () => import('../../components/yotpo/YotpoReviews.vue' /* webpackChunkName: "components/yotpo-reviews" */).then(c => wrapFunctional(c.default || c))
export const YotpoTestimonials = () => import('../../components/yotpo/YotpoTestimonials.vue' /* webpackChunkName: "components/yotpo-testimonials" */).then(c => wrapFunctional(c.default || c))
export const AccountOrdersNoOrders = () => import('../../components/account/orders/NoOrders.vue' /* webpackChunkName: "components/account-orders-no-orders" */).then(c => wrapFunctional(c.default || c))
export const AccountOrdersOrderList = () => import('../../components/account/orders/OrderList.vue' /* webpackChunkName: "components/account-orders-order-list" */).then(c => wrapFunctional(c.default || c))
export const AccountOrdersOrderListItem = () => import('../../components/account/orders/OrderListItem.vue' /* webpackChunkName: "components/account-orders-order-list-item" */).then(c => wrapFunctional(c.default || c))
export const AccountOrdersOrderStatus = () => import('../../components/account/orders/OrderStatus.vue' /* webpackChunkName: "components/account-orders-order-status" */).then(c => wrapFunctional(c.default || c))
export const CampaignBlackFridayCategoryNav = () => import('../../components/campaign/black-friday/BlackFridayCategoryNav.vue' /* webpackChunkName: "components/campaign-black-friday-category-nav" */).then(c => wrapFunctional(c.default || c))
export const CategoryAttributeCategories = () => import('../../components/category/attributeCategories/AttributeCategories.vue' /* webpackChunkName: "components/category-attribute-categories" */).then(c => wrapFunctional(c.default || c))
export const CategoryProductFiltersFilterCheckbox = () => import('../../components/category/product-filters/FilterCheckbox.vue' /* webpackChunkName: "components/category-product-filters-filter-checkbox" */).then(c => wrapFunctional(c.default || c))
export const CategoryProductFiltersFilterDropdown = () => import('../../components/category/product-filters/FilterDropdown.vue' /* webpackChunkName: "components/category-product-filters-filter-dropdown" */).then(c => wrapFunctional(c.default || c))
export const CategoryProductFiltersFilterList = () => import('../../components/category/product-filters/FilterList.vue' /* webpackChunkName: "components/category-product-filters-filter-list" */).then(c => wrapFunctional(c.default || c))
export const CategoryProductFiltersFilterMenu = () => import('../../components/category/product-filters/FilterMenu.vue' /* webpackChunkName: "components/category-product-filters-filter-menu" */).then(c => wrapFunctional(c.default || c))
export const CategoryProductFiltersFilterSelectionList = () => import('../../components/category/product-filters/FilterSelectionList.vue' /* webpackChunkName: "components/category-product-filters-filter-selection-list" */).then(c => wrapFunctional(c.default || c))
export const CategoryProductFiltersFilterSelectionListAttribute = () => import('../../components/category/product-filters/FilterSelectionListAttribute.vue' /* webpackChunkName: "components/category-product-filters-filter-selection-list-attribute" */).then(c => wrapFunctional(c.default || c))
export const CategoryProductFiltersFilterToggle = () => import('../../components/category/product-filters/FilterToggle.vue' /* webpackChunkName: "components/category-product-filters-filter-toggle" */).then(c => wrapFunctional(c.default || c))
export const CategoryProductFilters = () => import('../../components/category/product-filters/ProductFilters.vue' /* webpackChunkName: "components/category-product-filters" */).then(c => wrapFunctional(c.default || c))
export const CategorySubcategoriesSubCategories = () => import('../../components/category/subcategories/SubCategories.vue' /* webpackChunkName: "components/category-subcategories-sub-categories" */).then(c => wrapFunctional(c.default || c))
export const ContentJoulukauppaCategoryNav = () => import('../../components/content/joulukauppa/CategoryNav.vue' /* webpackChunkName: "components/content-joulukauppa-category-nav" */).then(c => wrapFunctional(c.default || c))
export const ContentPaymentsSveaPartPaymentTerms = () => import('../../components/content/payments/SveaPartPaymentTerms.vue' /* webpackChunkName: "components/content-payments-svea-part-payment-terms" */).then(c => wrapFunctional(c.default || c))
export const FrontpageContentBlocksNewsletterSignup = () => import('../../components/frontpage/content-blocks/NewsletterSignup.vue' /* webpackChunkName: "components/frontpage-content-blocks-newsletter-signup" */).then(c => wrapFunctional(c.default || c))
export const FrontpageYotpoReviewFrontpage = () => import('../../components/frontpage/yotpo-reviews/YotpoReviewFrontpage.vue' /* webpackChunkName: "components/frontpage-yotpo-review-frontpage" */).then(c => wrapFunctional(c.default || c))
export const FrontpageYotpoReviewsFrontpage = () => import('../../components/frontpage/yotpo-reviews/YotpoReviewsFrontpage.vue' /* webpackChunkName: "components/frontpage-yotpo-reviews-frontpage" */).then(c => wrapFunctional(c.default || c))
export const PageMessageWidgetsCentralizeAndSave = () => import('../../components/page-message/widgets/CentralizeAndSave.vue' /* webpackChunkName: "components/page-message-widgets-centralize-and-save" */).then(c => wrapFunctional(c.default || c))
export const ProductMediaGalleryLightBox = () => import('../../components/product/media-gallery/LightBox.vue' /* webpackChunkName: "components/product-media-gallery-light-box" */).then(c => wrapFunctional(c.default || c))
export const ProductMediaGalleryPixmoorImage = () => import('../../components/product/media-gallery/PixmoorImage.vue' /* webpackChunkName: "components/product-media-gallery-pixmoor-image" */).then(c => wrapFunctional(c.default || c))
export const ProductMediaGallery = () => import('../../components/product/media-gallery/ProductMediaGallery.vue' /* webpackChunkName: "components/product-media-gallery" */).then(c => wrapFunctional(c.default || c))
export const ProductMediaGalleryReviewImages = () => import('../../components/product/media-gallery/ReviewImages.vue' /* webpackChunkName: "components/product-media-gallery-review-images" */).then(c => wrapFunctional(c.default || c))
export const ProductTileCarousel = () => import('../../components/product/tile-carousel/ProductTileCarousel.vue' /* webpackChunkName: "components/product-tile-carousel" */).then(c => wrapFunctional(c.default || c))
export const ProductProductOptionsCustomOption = () => import('../../components/product/product-options/CustomOption.vue' /* webpackChunkName: "components/product-product-options-custom-option" */).then(c => wrapFunctional(c.default || c))
export const ProductProductOptionsCustomSize = () => import('../../components/product/product-options/CustomSize.vue' /* webpackChunkName: "components/product-product-options-custom-size" */).then(c => wrapFunctional(c.default || c))
export const ProductProductOptionsCustomUpholstery = () => import('../../components/product/product-options/CustomUpholstery.vue' /* webpackChunkName: "components/product-product-options-custom-upholstery" */).then(c => wrapFunctional(c.default || c))
export const ProductProductOptionsOptionDetails = () => import('../../components/product/product-options/OptionDetails.vue' /* webpackChunkName: "components/product-product-options-option-details" */).then(c => wrapFunctional(c.default || c))
export const ProductProductOptionsOptionSet = () => import('../../components/product/product-options/OptionSet.vue' /* webpackChunkName: "components/product-product-options-option-set" */).then(c => wrapFunctional(c.default || c))
export const SiteHeaderMegaMenu = () => import('../../components/site-header/mega-menu/MegaMenu.vue' /* webpackChunkName: "components/site-header-mega-menu" */).then(c => wrapFunctional(c.default || c))
export const SiteHeaderMegaSubMenu = () => import('../../components/site-header/mega-menu/MegaSubMenu.vue' /* webpackChunkName: "components/site-header-mega-sub-menu" */).then(c => wrapFunctional(c.default || c))
export const SiteHeaderMobileMenuArrowLink = () => import('../../components/site-header/mobile-menu/ArrowLink.vue' /* webpackChunkName: "components/site-header-mobile-menu-arrow-link" */).then(c => wrapFunctional(c.default || c))
export const SiteHeaderMobileMenuItem = () => import('../../components/site-header/mobile-menu/MenuItem.vue' /* webpackChunkName: "components/site-header-mobile-menu-item" */).then(c => wrapFunctional(c.default || c))
export const SiteMenu = () => import('../../components/site-header/mobile-menu/SiteMenu.vue' /* webpackChunkName: "components/site-menu" */).then(c => wrapFunctional(c.default || c))
export const SiteHeaderMobileMenuSubMenu = () => import('../../components/site-header/mobile-menu/SubMenu.vue' /* webpackChunkName: "components/site-header-mobile-menu-sub-menu" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIcons = () => import('../../components/veke-ui/icons/index.ts' /* webpackChunkName: "components/veke-ui-icons" */).then(c => wrapFunctional(c.default || c))
export const WishlistMenuAddToMany = () => import('../../components/wishlist/menus/WishlistMenuAddToMany.vue' /* webpackChunkName: "components/wishlist-menu-add-to-many" */).then(c => wrapFunctional(c.default || c))
export const WishlistMenuAddToOne = () => import('../../components/wishlist/menus/WishlistMenuAddToOne.vue' /* webpackChunkName: "components/wishlist-menu-add-to-one" */).then(c => wrapFunctional(c.default || c))
export const WishlistMenuContext = () => import('../../components/wishlist/menus/WishlistMenuContext.vue' /* webpackChunkName: "components/wishlist-menu-context" */).then(c => wrapFunctional(c.default || c))
export const WishlistMenuCreate = () => import('../../components/wishlist/menus/WishlistMenuCreate.vue' /* webpackChunkName: "components/wishlist-menu-create" */).then(c => wrapFunctional(c.default || c))
export const WishlistMenuRename = () => import('../../components/wishlist/menus/WishlistMenuRename.vue' /* webpackChunkName: "components/wishlist-menu-rename" */).then(c => wrapFunctional(c.default || c))
export const AccountOrdersOrderComments = () => import('../../components/account/orders/order/Comments.vue' /* webpackChunkName: "components/account-orders-order-comments" */).then(c => wrapFunctional(c.default || c))
export const AccountOrdersOrderCustomer = () => import('../../components/account/orders/order/Customer.vue' /* webpackChunkName: "components/account-orders-order-customer" */).then(c => wrapFunctional(c.default || c))
export const AccountOrdersOrderDelivery = () => import('../../components/account/orders/order/Delivery.vue' /* webpackChunkName: "components/account-orders-order-delivery" */).then(c => wrapFunctional(c.default || c))
export const AccountOrdersOrderEvents = () => import('../../components/account/orders/order/Events.vue' /* webpackChunkName: "components/account-orders-order-events" */).then(c => wrapFunctional(c.default || c))
export const AccountOrdersOrderItems = () => import('../../components/account/orders/order/Items.vue' /* webpackChunkName: "components/account-orders-order-items" */).then(c => wrapFunctional(c.default || c))
export const AccountOrdersOrderItemsPreview = () => import('../../components/account/orders/order/ItemsPreview.vue' /* webpackChunkName: "components/account-orders-order-items-preview" */).then(c => wrapFunctional(c.default || c))
export const AccountOrdersOrderHeader = () => import('../../components/account/orders/order/OrderHeader.vue' /* webpackChunkName: "components/account-orders-order-header" */).then(c => wrapFunctional(c.default || c))
export const AccountOrdersOrderPayment = () => import('../../components/account/orders/order/Payment.vue' /* webpackChunkName: "components/account-orders-order-payment" */).then(c => wrapFunctional(c.default || c))
export const AccountOrdersOrderProgressBar = () => import('../../components/account/orders/order/ProgressBar.vue' /* webpackChunkName: "components/account-orders-order-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const CategoryProductFiltersProductFilterTypesCheckboxFilter = () => import('../../components/category/product-filters/product-filter-types/CheckboxFilter.vue' /* webpackChunkName: "components/category-product-filters-product-filter-types-checkbox-filter" */).then(c => wrapFunctional(c.default || c))
export const CategoryProductFiltersProductFilterTypesColorFilter = () => import('../../components/category/product-filters/product-filter-types/ColorFilter.vue' /* webpackChunkName: "components/category-product-filters-product-filter-types-color-filter" */).then(c => wrapFunctional(c.default || c))
export const CategoryProductFiltersProductFilterTypesRangeFilter = () => import('../../components/category/product-filters/product-filter-types/RangeFilter.vue' /* webpackChunkName: "components/category-product-filters-product-filter-types-range-filter" */).then(c => wrapFunctional(c.default || c))
export const CategoryProductFiltersProductFilterTypesToggleFilter = () => import('../../components/category/product-filters/product-filter-types/ToggleFilter.vue' /* webpackChunkName: "components/category-product-filters-product-filter-types-toggle-filter" */).then(c => wrapFunctional(c.default || c))
export const CheckoutStepsCustomerStep = () => import('../../components/checkout/steps/customer/CustomerStep.vue' /* webpackChunkName: "components/checkout-steps-customer-step" */).then(c => wrapFunctional(c.default || c))
export const CheckoutStepsDeliveryMethodGroup = () => import('../../components/checkout/steps/delivery/DeliveryMethodGroup.vue' /* webpackChunkName: "components/checkout-steps-delivery-method-group" */).then(c => wrapFunctional(c.default || c))
export const CheckoutStepsDeliveryMethods = () => import('../../components/checkout/steps/delivery/DeliveryMethods.vue' /* webpackChunkName: "components/checkout-steps-delivery-methods" */).then(c => wrapFunctional(c.default || c))
export const CheckoutStepsDeliveryStep = () => import('../../components/checkout/steps/delivery/DeliveryStep.vue' /* webpackChunkName: "components/checkout-steps-delivery-step" */).then(c => wrapFunctional(c.default || c))
export const CheckoutStepsPaymentMethodGroup = () => import('../../components/checkout/steps/payment/PaymentMethodGroup.vue' /* webpackChunkName: "components/checkout-steps-payment-method-group" */).then(c => wrapFunctional(c.default || c))
export const CheckoutStepsPaymentMethods = () => import('../../components/checkout/steps/payment/PaymentMethods.vue' /* webpackChunkName: "components/checkout-steps-payment-methods" */).then(c => wrapFunctional(c.default || c))
export const CheckoutStepsPaymentStep = () => import('../../components/checkout/steps/payment/PaymentStep.vue' /* webpackChunkName: "components/checkout-steps-payment-step" */).then(c => wrapFunctional(c.default || c))
export const CheckoutStepsSummaryOrderComment = () => import('../../components/checkout/steps/summary/OrderComment.vue' /* webpackChunkName: "components/checkout-steps-summary-order-comment" */).then(c => wrapFunctional(c.default || c))
export const CheckoutStepsSummaryOrderItems = () => import('../../components/checkout/steps/summary/OrderItems.vue' /* webpackChunkName: "components/checkout-steps-summary-order-items" */).then(c => wrapFunctional(c.default || c))
export const CheckoutStepsSummaryOrderSummary = () => import('../../components/checkout/steps/summary/OrderSummary.vue' /* webpackChunkName: "components/checkout-steps-summary-order-summary" */).then(c => wrapFunctional(c.default || c))
export const CheckoutStepsSummaryPartialDelivery = () => import('../../components/checkout/steps/summary/PartialDelivery.vue' /* webpackChunkName: "components/checkout-steps-summary-partial-delivery" */).then(c => wrapFunctional(c.default || c))
export const CheckoutStepsSummaryPayOrder = () => import('../../components/checkout/steps/summary/PayOrder.vue' /* webpackChunkName: "components/checkout-steps-summary-pay-order" */).then(c => wrapFunctional(c.default || c))
export const CheckoutStepsSummaryStep = () => import('../../components/checkout/steps/summary/SummaryStep.vue' /* webpackChunkName: "components/checkout-steps-summary-step" */).then(c => wrapFunctional(c.default || c))
export const ProductProductOptionsOptionTypesImageOption = () => import('../../components/product/product-options/option-types/ImageOption.vue' /* webpackChunkName: "components/product-product-options-option-types-image-option" */).then(c => wrapFunctional(c.default || c))
export const ProductProductOptionsOptionTypesTextOption = () => import('../../components/product/product-options/option-types/TextOption.vue' /* webpackChunkName: "components/product-product-options-option-types-text-option" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibAlertCircleIcon = () => import('../../components/veke-ui/icons/lib/AlertCircleIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-alert-circle-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibArmchairIcon = () => import('../../components/veke-ui/icons/lib/ArmchairIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-armchair-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibArrowDownIcon = () => import('../../components/veke-ui/icons/lib/ArrowDownIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-arrow-down-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibArrowLeftIcon = () => import('../../components/veke-ui/icons/lib/ArrowLeftIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-arrow-left-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibArrowRightIcon = () => import('../../components/veke-ui/icons/lib/ArrowRightIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-arrow-right-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibArrowUpIcon = () => import('../../components/veke-ui/icons/lib/ArrowUpIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-arrow-up-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibAtSignIcon = () => import('../../components/veke-ui/icons/lib/AtSignIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-at-sign-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibAwardIcon = () => import('../../components/veke-ui/icons/lib/AwardIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-award-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibBadgePercentIcon = () => import('../../components/veke-ui/icons/lib/BadgePercentIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-badge-percent-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibBellOffIcon = () => import('../../components/veke-ui/icons/lib/BellOffIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-bell-off-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibBoxIcon = () => import('../../components/veke-ui/icons/lib/BoxIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-box-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibCalendarIcon = () => import('../../components/veke-ui/icons/lib/CalendarIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-calendar-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibCameraIcon = () => import('../../components/veke-ui/icons/lib/CameraIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-camera-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibCartIcon = () => import('../../components/veke-ui/icons/lib/CartIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-cart-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibCheckCircleIcon = () => import('../../components/veke-ui/icons/lib/CheckCircleIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-check-circle-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibCheckFilledIcon = () => import('../../components/veke-ui/icons/lib/CheckFilledIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-check-filled-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibCheckIcon = () => import('../../components/veke-ui/icons/lib/CheckIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-check-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibChevronDownIcon = () => import('../../components/veke-ui/icons/lib/ChevronDownIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-chevron-down-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibChevronLeftIcon = () => import('../../components/veke-ui/icons/lib/ChevronLeftIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-chevron-left-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibChevronRightIcon = () => import('../../components/veke-ui/icons/lib/ChevronRightIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-chevron-right-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibChevronUpIcon = () => import('../../components/veke-ui/icons/lib/ChevronUpIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-chevron-up-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibCircleDotIcon = () => import('../../components/veke-ui/icons/lib/CircleDotIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-circle-dot-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibClockFilledIcon = () => import('../../components/veke-ui/icons/lib/ClockFilledIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-clock-filled-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibClockIcon = () => import('../../components/veke-ui/icons/lib/ClockIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-clock-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibClubIcon = () => import('../../components/veke-ui/icons/lib/ClubIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-club-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibCopyIcon = () => import('../../components/veke-ui/icons/lib/CopyIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-copy-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibCreditCardIcon = () => import('../../components/veke-ui/icons/lib/CreditCardIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-credit-card-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibEditIcon = () => import('../../components/veke-ui/icons/lib/EditIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-edit-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibEllipsisLoadingIcon = () => import('../../components/veke-ui/icons/lib/EllipsisLoadingIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-ellipsis-loading-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibEuroIcon = () => import('../../components/veke-ui/icons/lib/EuroIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-euro-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibExternalLinkIcon = () => import('../../components/veke-ui/icons/lib/ExternalLinkIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-external-link-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibEyeIcon = () => import('../../components/veke-ui/icons/lib/EyeIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-eye-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibEyeOffIcon = () => import('../../components/veke-ui/icons/lib/EyeOffIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-eye-off-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibFacebookIcon = () => import('../../components/veke-ui/icons/lib/FacebookIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-facebook-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibFileIcon = () => import('../../components/veke-ui/icons/lib/FileIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-file-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibFlagIcon = () => import('../../components/veke-ui/icons/lib/FlagIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-flag-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibFrownFaceIcon = () => import('../../components/veke-ui/icons/lib/FrownFaceIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-frown-face-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibGridIcon = () => import('../../components/veke-ui/icons/lib/GridIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-grid-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibHappyFaceIcon = () => import('../../components/veke-ui/icons/lib/HappyFaceIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-happy-face-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibHashIcon = () => import('../../components/veke-ui/icons/lib/HashIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-hash-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibHeartIcon = () => import('../../components/veke-ui/icons/lib/HeartIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-heart-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibHomeIcon = () => import('../../components/veke-ui/icons/lib/HomeIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-home-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibIcon = () => import('../../components/veke-ui/icons/lib/Icon.ts' /* webpackChunkName: "components/veke-ui-icons-lib-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibImageIcon = () => import('../../components/veke-ui/icons/lib/ImageIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-image-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibInfoIcon = () => import('../../components/veke-ui/icons/lib/InfoIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-info-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibInstagramIcon = () => import('../../components/veke-ui/icons/lib/InstagramIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-instagram-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibKeyIcon = () => import('../../components/veke-ui/icons/lib/KeyIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-key-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibListXIcon = () => import('../../components/veke-ui/icons/lib/ListXIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-list-x-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibMailIcon = () => import('../../components/veke-ui/icons/lib/MailIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-mail-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibMapPinIcon = () => import('../../components/veke-ui/icons/lib/MapPinIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-map-pin-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibMaximizeIcon = () => import('../../components/veke-ui/icons/lib/MaximizeIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-maximize-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibMenuIcon = () => import('../../components/veke-ui/icons/lib/MenuIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-menu-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibMessageSquareIcon = () => import('../../components/veke-ui/icons/lib/MessageSquareIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-message-square-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibMilieuIcon = () => import('../../components/veke-ui/icons/lib/MilieuIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-milieu-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibMinimizeIcon = () => import('../../components/veke-ui/icons/lib/MinimizeIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-minimize-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibMinusCircleIcon = () => import('../../components/veke-ui/icons/lib/MinusCircleIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-minus-circle-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibMinusIcon = () => import('../../components/veke-ui/icons/lib/MinusIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-minus-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibMoreVerticalIcon = () => import('../../components/veke-ui/icons/lib/MoreVerticalIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-more-vertical-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibNonMilieuIcon = () => import('../../components/veke-ui/icons/lib/NonMilieuIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-non-milieu-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibPackageCheckIcon = () => import('../../components/veke-ui/icons/lib/PackageCheckIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-package-check-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibPackageIcon = () => import('../../components/veke-ui/icons/lib/PackageIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-package-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibPackageMinusIcon = () => import('../../components/veke-ui/icons/lib/PackageMinusIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-package-minus-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibPackagePlusIcon = () => import('../../components/veke-ui/icons/lib/PackagePlusIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-package-plus-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibPaperclipIcon = () => import('../../components/veke-ui/icons/lib/PaperclipIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-paperclip-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibPartyPopperIcon = () => import('../../components/veke-ui/icons/lib/PartyPopperIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-party-popper-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibPawIcon = () => import('../../components/veke-ui/icons/lib/PawIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-paw-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibPercentCircleIcon = () => import('../../components/veke-ui/icons/lib/PercentCircleIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-percent-circle-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibPercentIcon = () => import('../../components/veke-ui/icons/lib/PercentIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-percent-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibPercentSquareIcon = () => import('../../components/veke-ui/icons/lib/PercentSquareIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-percent-square-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibPhoneIcon = () => import('../../components/veke-ui/icons/lib/PhoneIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-phone-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibPinterestIcon = () => import('../../components/veke-ui/icons/lib/PinterestIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-pinterest-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibPlusCircleIcon = () => import('../../components/veke-ui/icons/lib/PlusCircleIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-plus-circle-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibPlusIcon = () => import('../../components/veke-ui/icons/lib/PlusIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-plus-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibRefreshIcon = () => import('../../components/veke-ui/icons/lib/RefreshIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-refresh-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibRotate3dIcon = () => import('../../components/veke-ui/icons/lib/Rotate3dIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-rotate3d-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibRowsFourIcon = () => import('../../components/veke-ui/icons/lib/RowsFourIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-rows-four-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibRulerIcon = () => import('../../components/veke-ui/icons/lib/RulerIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-ruler-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibSadFaceIcon = () => import('../../components/veke-ui/icons/lib/SadFaceIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-sad-face-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibSearchIcon = () => import('../../components/veke-ui/icons/lib/SearchIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-search-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibSettingsIcon = () => import('../../components/veke-ui/icons/lib/SettingsIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-settings-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibShareIcon = () => import('../../components/veke-ui/icons/lib/ShareIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-share-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibShipIcon = () => import('../../components/veke-ui/icons/lib/ShipIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-ship-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibSlashIcon = () => import('../../components/veke-ui/icons/lib/SlashIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-slash-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibSlidersIcon = () => import('../../components/veke-ui/icons/lib/SlidersIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-sliders-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibSmileyFaceIcon = () => import('../../components/veke-ui/icons/lib/SmileyFaceIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-smiley-face-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibStarHalfIcon = () => import('../../components/veke-ui/icons/lib/StarHalfIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-star-half-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibStarIcon = () => import('../../components/veke-ui/icons/lib/StarIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-star-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibStoreIcon = () => import('../../components/veke-ui/icons/lib/StoreIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-store-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibTagIcon = () => import('../../components/veke-ui/icons/lib/TagIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-tag-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibThumbsDownIcon = () => import('../../components/veke-ui/icons/lib/ThumbsDownIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-thumbs-down-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibThumbsUpIcon = () => import('../../components/veke-ui/icons/lib/ThumbsUpIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-thumbs-up-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibTrashIcon = () => import('../../components/veke-ui/icons/lib/TrashIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-trash-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibTruckIcon = () => import('../../components/veke-ui/icons/lib/TruckIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-truck-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibUndoIcon = () => import('../../components/veke-ui/icons/lib/UndoIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-undo-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibUserIcon = () => import('../../components/veke-ui/icons/lib/UserIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-user-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibWrenchIcon = () => import('../../components/veke-ui/icons/lib/WrenchIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-wrench-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibXCircleIcon = () => import('../../components/veke-ui/icons/lib/XCircleIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-x-circle-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibXFilledIcon = () => import('../../components/veke-ui/icons/lib/XFilledIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-x-filled-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibXIcon = () => import('../../components/veke-ui/icons/lib/XIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-x-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibYoutubeIcon = () => import('../../components/veke-ui/icons/lib/YoutubeIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-youtube-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibZoomInIcon = () => import('../../components/veke-ui/icons/lib/ZoomInIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-zoom-in-icon" */).then(c => wrapFunctional(c.default || c))
export const VekeUiIconsLibZoomOutIcon = () => import('../../components/veke-ui/icons/lib/ZoomOutIcon.vue' /* webpackChunkName: "components/veke-ui-icons-lib-zoom-out-icon" */).then(c => wrapFunctional(c.default || c))
export const CheckoutStepsDeliveryInputList = () => import('../../components/checkout/steps/delivery/inputs/DeliveryInputList.vue' /* webpackChunkName: "components/checkout-steps-delivery-input-list" */).then(c => wrapFunctional(c.default || c))
export const CheckoutStepsDeliveryPickupList = () => import('../../components/checkout/steps/delivery/inputs/DeliveryPickupList.vue' /* webpackChunkName: "components/checkout-steps-delivery-pickup-list" */).then(c => wrapFunctional(c.default || c))
export const CheckoutStepsPaymentInputImageGrid = () => import('../../components/checkout/steps/payment/inputs/PaymentInputImageGrid.vue' /* webpackChunkName: "components/checkout-steps-payment-input-image-grid" */).then(c => wrapFunctional(c.default || c))
export const CheckoutStepsPaymentInputList = () => import('../../components/checkout/steps/payment/inputs/PaymentInputList.vue' /* webpackChunkName: "components/checkout-steps-payment-input-list" */).then(c => wrapFunctional(c.default || c))
export const CheckoutStepsPaymentInputPartPayment = () => import('../../components/checkout/steps/payment/inputs/PaymentInputPartPayment.vue' /* webpackChunkName: "components/checkout-steps-payment-input-part-payment" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
